import { Typography } from "@material-ui/core";
import Campaign from "@socialplug/common/interfaces/campaign";
import Commit from "@socialplug/common/interfaces/commit";
import Offer from "@socialplug/common/interfaces/offer";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";

const CampaignStatistics: React.FC<any> = (props) => {
  const campaign: Campaign = props.campaign;
  const [commits, setCommits] = useState<Commit[]>([]);
  const [offers, setOffers] = useState<Offer[]>();
  const [, listCommits] = useApi("GET", "commit", "list");
  const [, listOffers] = useApi("GET", "offer", "list");

  useEffect(() => {
    (async () => {
      if (campaign !== null && campaign !== undefined) {
        try {
          const foundCommits: Commit[] = await listCommits({
            queryParams: { campaignId: campaign.id },
          });
          setCommits(foundCommits);
          const foundOffers: Offer[] = await listOffers({
            queryParams: { campaignId: campaign.id, accepted: true },
          });
          setOffers(foundOffers);
        } catch {}
      }
    })();
  }, [campaign, listCommits, listOffers]);

  const redemptionsRemaining = () => {
    var toReturn = campaign.maximumRedemptions;
    commits.forEach((commit) => {
      if (commit.state === "Completed") toReturn--;
    });
    if (toReturn < 0) return 0;
    return toReturn;
  };

  const getTotalSpent = () => {
    var toReturn = 0;
    if (offers !== undefined) {
      offers.forEach((offer) => {
        toReturn += offer.amount;
      });
    }
    return toReturn;
  };

  const getTotalPosts = () => {
    var toReturn = 0;
    if (commits !== undefined) {
      commits.forEach((commit) => {
        if (commit.state === "Completed") toReturn++;
      });
    }
    return toReturn;
  };

  return (
    <React.Fragment>
      <div className="w-full ml-4 lg:ml-10 mb-10">
        <div className="grid grid-rows-4 lg:grid-rows-3 grid-cols-3 gap-4 lg:grid-cols-4 grid-flow-row w-full lg:place-content-center lg:place-items-center">
          <div className="flex flex-col w-1/4">
            <Typography className="text-sm lg:text-md">TOTAL POSTS</Typography>
            <Typography className="text-gray-700 text-2xl">
              {getTotalPosts()}
            </Typography>
          </div>

          <div className="flex flex-col w-1/4">
            <Typography className="text-sm lg:text-md">
              REDEMPTIONS REMAINING
            </Typography>
            <Typography className="text-gray-700 text-2xl">
              {redemptionsRemaining()}
            </Typography>
          </div>

          <div className="flex flex-col w-1/4">
            <Typography className="text-sm lg:text-md">EXPIRES</Typography>
            <Typography className="text-gray-700 text-xl">
              {new Date(campaign.endDate).toLocaleDateString()}
            </Typography>
          </div>

          {campaign?.rewardType === "Money" && (
            <div className="flex flex-col w-1/3 lg:w-1/4">
              <Typography className="text-sm lg:text-md">
                TOTAL SPENT
              </Typography>
              <Typography className="text-gray-700 text-2xl">
                £{getTotalSpent()} / £{campaign.budget}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CampaignStatistics;
