import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";
import AllCreatorsRow from "./AllCreatorsRow";

const AllCreatorsTable: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [usersToDisplay, setUsersToDisplay] = useState<User[]>([]);
  const [, listAllCreators] = useApi("GET", "admin", "listAllCreators");
  const [statusFilter, setStatusFilter] = useState("");

  useEffect(() => {
    (async () => {
      const foundUsers: User[] = await listAllCreators();
      setUsers(foundUsers);
      setUsersToDisplay(foundUsers);
    })();
  }, [listAllCreators]);

  useEffect(() => {
    var newUsersToDisplay: User[] = [];
    if (statusFilter !== "") {
      users.forEach((user) => {
        if (user.status === statusFilter) newUsersToDisplay.push(user);
      });
    } else {
      newUsersToDisplay = users;
    }
    setUsersToDisplay(newUsersToDisplay);
  }, [statusFilter, users]);

  const handleStatusChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStatusFilter((event.target as HTMLInputElement).value);
  };

  return (
    <React.Fragment>
      <FormControl component="fieldset">
        <FormLabel component="legend">Creator Status</FormLabel>
        <RadioGroup
          aria-label="Type"
          value={statusFilter}
          name="type"
          onChange={handleStatusChange}
        >
          <div className="flex">
            <FormControlLabel value="" control={<Radio />} label="All" />
            <FormControlLabel
              value="Approved"
              control={<Radio />}
              label="Approved"
            />
            <FormControlLabel
              value="Waiting"
              control={<Radio />}
              label="Waiting"
            />
            <FormControlLabel
              value="Rejected"
              control={<Radio />}
              label="Rejected"
            />
            <FormControlLabel
              value="Incomplete"
              control={<Radio />}
              label="Incomplete"
            />
          </div>
        </RadioGroup>
      </FormControl>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" className="font-bold">
                Name
              </TableCell>
              <TableCell align="center" className="font-bold">
                Instagram Handle
              </TableCell>
              <TableCell align="center" className="font-bold">
                TikTok Handle
              </TableCell>
              <TableCell align="center" className="font-bold">
                Email
              </TableCell>
              <TableCell align="center" className="font-bold">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersToDisplay.map((user: User) => (
              <AllCreatorsRow user={user} key={user.id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default AllCreatorsTable;
