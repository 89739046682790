import { Auth } from "@aws-amplify/auth";
import EnvironmentGraphic from "@socialplug/common/components/environment-graphic";
import Brand from "@socialplug/common/interfaces/brand";
import { useApi } from "@socialplug/common/utilities/api";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import ErrorContext from "../../shared/context/ErrorContext";
import { Campaign } from "../../shared/interfaces";
import SPError from "../../shared/interfaces/error";
import CheckoutStarted from "./components/paymentProcessing/CheckoutStarted";
import InactiveSub from "./components/paymentProcessing/InactiveSub";
import PaymentHolding from "./components/paymentProcessing/PaymentHolding";
import BrandContext from "./context/BrandContext";
import OnboardingContext, {
  OnboardingState,
} from "./context/OnboardingContext";
import Authenticate from "./pages/auth/Authenticate";
import CampaignDashboard from "./pages/dashboard/CampaignDashboard";
import Dashboard from "./pages/dashboard/Dashboard";
import InfluencerProfile from "./pages/dashboard/InfluencerProfile";
import ErrorMessage from "./pages/ErrorMessage";
import RedeemScreen from "./pages/offer/RedeemScreen";
import RegisterBrand from "./pages/onboarding/RegisterBrand";
import Welcome from "./pages/onboarding/Welcome";
import SplashScreen from "./pages/SplashScreen";

const Routes = withRouter(() => {
  const [, fetchBrands] = useApi("GET", "brand", "list");
  const [, listCampaigns] = useApi("GET", "campaign", "list");

  const [brands, setBrands] = useState<Brand[] | undefined>([]);
  const [activeBrand, setActiveBrand] = useState<Brand | undefined>(undefined);
  const [brandCampaigns, setBrandCampaigns] = useState<Campaign[] | undefined>(
    []
  );

  const brandContextProviderValue = {
    brands,
    setBrands,
    activeBrand,
    setActiveBrand,
    brandCampaigns,
    setBrandCampaigns,
  };
  const { error } = useContext(ErrorContext);
  const { onboardingState, setOnboardingState } = useContext(OnboardingContext);
  console.log("Error context:" + JSON.stringify(error));

  useEffect(() => {
    (async () => {
      try {
        // setError(undefined)
        const amplifyUser = await Auth.currentAuthenticatedUser();
        console.log("auth user:" + JSON.stringify(amplifyUser));
        let authenticated = !!amplifyUser;
        if (!authenticated) {
          console.log("No user found");
          setOnboardingState(OnboardingState.Unauthenticated);
        } else {
          const brandList: Brand[] = await fetchBrands();
          console.log("Brands:" + JSON.stringify(brandList));
          const hasBrand =
            brandList && brandList != null && brandList.length > 0;
          const hasCreatedCampaign =
            hasBrand && brandList.length > 0 && brandList[0].hasCreatedCampaign;

          if (hasBrand) {
            setActiveBrand(brandList[0]);
            const foundCampaigns = await listCampaigns({
              queryParams: { brandId: brandList[0].id },
            });
            setBrandCampaigns(foundCampaigns);
          }
          if (!hasBrand) {
            console.log("No Brand.");
            setOnboardingState(OnboardingState.NoBrand);
          } else if (!hasCreatedCampaign) {
            console.log("No Campaign.");
            setOnboardingState(OnboardingState.NoCampaigns);
          } else {
            console.log("Onboarding complete.");
            setOnboardingState(OnboardingState.Complete);
          }
        }
      } catch (e) {
        console.log("Error initializing app:" + e);
        if (e === "The user is not authenticated") {
          setOnboardingState(OnboardingState.Unauthenticated);
        } else {
          setOnboardingState(OnboardingState.Unauthenticated);
        }
      }
    })();
  }, []);

  if (onboardingState === OnboardingState.Loading) {
    return <SplashScreen />;
  }

  console.log("brand sub status - " + activeBrand?.subscriptionStatus);

  return (
    <BrandContext.Provider value={brandContextProviderValue}>
      <Switch>
        <Route path="/paymentProcessing" exact component={PaymentHolding} />
        <Route path="/inactiveSub" exact component={InactiveSub} />
        <Route path="/continueCheckout" exact component={CheckoutStarted} />

        {activeBrand?.subscriptionStatus === "CheckoutCompleted" &&
          !activeBrand?.admin && <Redirect from="/" to="/paymentProcessing" />}
        {activeBrand?.subscriptionStatus === "Inactive" &&
          !activeBrand?.admin && <Redirect to="/inactiveSub" />}
        {activeBrand?.subscriptionStatus === "CheckoutStarted" &&
          !activeBrand?.admin && <Redirect to="/continueCheckout" />}

        <Route path="/error" component={ErrorMessage} />
        <Route path="/offer/:commit_id/redeem" component={RedeemScreen} />
        <Route path="/auth" exact component={Authenticate} />
        <Route path="/register" exact component={RegisterBrand} />
        <Route path="/welcome" exact component={Welcome} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/campaign/:id" component={CampaignDashboard} />

        <Route
          path="/admin/influencer/:uid"
          exact
          component={InfluencerProfile}
        />
        {error && error.fatal && <Redirect to="/error" />}

        {onboardingState === OnboardingState.Unauthenticated && (
          <Redirect to="/auth" />
        )}

        {onboardingState === OnboardingState.NoCampaigns && (
          <Redirect exact from="/" to="/dashboard/campaign/create" />
        )}

        {onboardingState === OnboardingState.Complete && (
          <Redirect exact from="/" to="/dashboard/campaigns/active" />
        )}

        {onboardingState === OnboardingState.NoBrand && (
          <Redirect exact from="/" to="/register" />
        )}
      </Switch>
    </BrandContext.Provider>
  );
});
function App() {
  const [onboardingState, setOnboardingState] = useState<OnboardingState>(
    OnboardingState.Loading
  );

  const [error, setError] = useState<SPError | undefined>(undefined);
  const errorContextProviderValue = { error, setError };
  const onboardingContextProviderValue = {
    onboardingState,
    setOnboardingState,
  };

  return (
    <ErrorContext.Provider value={errorContextProviderValue}>
      <OnboardingContext.Provider value={onboardingContextProviderValue}>
        <main>
          <div className="max-w-screen">
            <EnvironmentGraphic />
            <Routes />
          </div>
        </main>
      </OnboardingContext.Provider>
    </ErrorContext.Provider>
  );
}

export default App;
