import {
  createDefaultImageReader,
  createDefaultImageWriter,
  locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb,
  openEditor,
  plugin_annotate_locale_en_gb,
  plugin_crop_locale_en_gb,
  plugin_filter_defaults,
  plugin_filter_locale_en_gb,
} from "@socialplug/common/libs/doka/doka";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useRef } from "react";

const defaultDokaLocale = {
  ...locale_en_gb,
  ...plugin_crop_locale_en_gb,
  ...plugin_filter_locale_en_gb,
  ...plugin_annotate_locale_en_gb,
  ...markup_editor_locale_en_gb,
};

type ImageUploadFieldProps = {
  purpose: string,
  id: string;
  onImageUploaded: (image: { id: string; url: string }) => void;
  children: any;
};

const toBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const ImageUploadField: React.FC<ImageUploadFieldProps> = ({
    purpose,
  id,
  onImageUploaded,
  children,
}) => {
  const [, callUploadImage] = useApi(
    "POST",
    "content",
    "brand-upload-content"
  );

  const uploadModifiedImage = async (
    state: any,
    options: any,
    onprogress: any
  ) => {
    const b64 = await toBase64(state.dest);

    const { file, key } = await callUploadImage({
      body: { file_base64_data: b64,  purpose:purpose },

    });

    onImageUploaded({ id: key, url: file });

    return state;
  };

  const onChangeFile = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    const file = event.target?.files[0];

    openEditor({
      imageReader: createDefaultImageReader(),
      imageWriter: createDefaultImageWriter({
        mimeType: "image/jpeg",
        targetSize: { width: 500, height: 500, fit: "cover", upscale: true },
        store: uploadModifiedImage,
      }),
      locale: defaultDokaLocale,
      src: file,
      ...plugin_filter_defaults,
      ...markup_editor_defaults,
      imageCropAspectRatio: 1,
    });
  };

  const selectFileRef = useRef<any>(null);

  return (
    <React.Fragment>
      <input
        id={id}
        type="file"
        ref={selectFileRef}
        style={{ display: "none" }}
        onChange={onChangeFile}
      />
      <div
        className="cursor-pointer w-full"
        onClick={() =>
          selectFileRef?.current
            ? selectFileRef.current.click()
            : console.log("No ref :(")
        }
      >
        {children}
      </div>
    </React.Fragment>
  );
};

export default ImageUploadField;
