import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from "@material-ui/core";
import React from "react";

const FormSelectField: React.FC<any> = ({
  inputProps,
  helperText,
  errorText,
  error,
  label,
  ...props
}) => (
  <FormControl
    variant="outlined"
    className={`w-96 mt-8 max-w-full ${props.className}`}
  >
    <InputLabel id={`input-label-${label}`}>{label}</InputLabel>
    <Select
      classes={{ root: "text-left" }}
      labelId={`input-label-${label}`}
      label={label}
      inputProps={inputProps}
      error={!!error}
      {...props}
    >
      {props.children}
    </Select>
    <FormHelperText>{error ? errorText : helperText}</FormHelperText>
  </FormControl>
);

export default FormSelectField;
