import { Typography } from "@material-ui/core";
import Campaign from "@socialplug/common/interfaces/campaign";
import Commit from "@socialplug/common/interfaces/commit";
import Offer from "@socialplug/common/interfaces/offer";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";

const CampaignPerformanceStats: React.FC<any> = (props) => {
  const commits: Commit[] = props.commits;
  const campaign: Campaign = props.campaign;
  const [offers, setOffers] = useState<Offer[]>();
  const [, getOffers] = useApi("GET", "offer", "list");

  useEffect(() => {
    (async () => {
      try {
        if (commits && commits != null) {
          const foundOffers: Offer[] = await getOffers({
            queryParams: { campaignId: campaign.id, accepted: true },
          });
          setOffers(foundOffers);
        }
      } catch {}
    })();
  }, [campaign.id, commits, getOffers]);

  const getPosts = () => {
    var toReturn = 0;
    if (commits !== undefined) {
      commits.forEach((commit) => {
        if (commit.state === "Completed") {
          toReturn++;
        }
      });
    }
    return toReturn;
  };

  const getTotalSpent = () => {
    var toReturn = 0;
    offers?.forEach((offer) => {
      if (offer.accepted) {
        toReturn += offer.amount;
      }
    });
    return toReturn;
  };

  return (
    <React.Fragment>
      <div className="flex flex-wrap -mx-1 overflow-hidden ml-4 mr-4">
        <div className="my-1 px-1 w-1/3 lg:w-1/4 overflow-hidden">
          <div className="flex flex-col">
            <Typography className="lg:text-lg font-bold">
              Completed Submissions
            </Typography>
            <Typography className="text-gray-700 lg:text-lg">
              {getPosts()}
            </Typography>
          </div>
        </div>

        {campaign?.rewardType === "Money" && (
          <div className="my-1 px-1 w-1/3 lg:w-1/4 overflow-hidden">
            <div className="flex flex-col">
              <Typography className="lg:text-lg font-bold">
                Total Spent
              </Typography>
              <Typography className="text-gray-700 lg:text-lg">
                £{getTotalSpent()} / £{campaign?.budget}
              </Typography>
            </div>
          </div>
        )}

      </div>
    </React.Fragment>
  );
};

export default CampaignPerformanceStats;
