import Amplify, { Hub } from "@aws-amplify/core";
import DateFnsUtils from "@date-io/date-fns";
import { StylesProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { getAmplifyConfigForEnv } from "@socialplug/common/amplify.config";
import {
  plugin_annotate,
  plugin_crop,
  plugin_filter,
  setPlugins as setDokaPlugins,
} from "@socialplug/common/libs/doka/doka";
import log from "loglevel";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AlertServiceProvider } from "./components/alerts/AlertServiceContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

setDokaPlugins(plugin_crop, plugin_filter, plugin_annotate);

log.setLevel(0);

Amplify.configure(getAmplifyConfigForEnv());

Hub.listen("auth", (data) => {
  const { payload } = data;
  log.info("Authentication Event Received: ", payload.event);
});

ReactDOM.render(
  <StylesProvider injectFirst>
    <BrowserRouter>
      <AlertServiceProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </AlertServiceProvider>
    </BrowserRouter>
  </StylesProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
