import * as React from "react";
import { Brand, Campaign } from "../../../shared/interfaces";
export interface BrandContainer {
  brands: Brand[] | undefined;
  activeBrand: Brand | undefined;
  brandCampaigns: Campaign[] | undefined;
  setBrands: (brand: Brand[] | undefined) => void;
  setActiveBrand: (brand: Brand | undefined) => void;
  setBrandCampaigns: (campaigns: Campaign[] | undefined) => void;
}
const BrandContext = React.createContext<BrandContainer>({
  brands: undefined,
  setBrands: () => {},
  activeBrand: undefined,
  setActiveBrand: () => {},
  brandCampaigns: undefined,
  setBrandCampaigns: () => {},
});

export default BrandContext;
