import * as React from 'react'

export enum OnboardingState {
    Unauthenticated,
    Loading,
    NoBrand,
    NoCampaigns,
    Complete,
}
export interface OnboardingContainer {
    onboardingState: OnboardingState,
    setOnboardingState: (onboardingState: OnboardingState)=>void
    ,

}
const OnboardingContext = React.createContext<OnboardingContainer>({onboardingState:OnboardingState.Loading, setOnboardingState: ()=>{}})

export default OnboardingContext



