import { Paper, Typography } from "@material-ui/core";
import Commit from "@socialplug/common/interfaces/commit";
import React, { useContext, useEffect, useState } from "react";
import CampaignPerformanceStats from "../../../components/CampaignPerformanceStats";
import UserChip from "../../../components/UserChip";
import CampaignContext from "../../../context/CampaignContext";

const CampaignStats = () => {
  const [completedCommits, setCompletedCommits] = useState<Commit[]>([]);
  const { campaign, allCommits } = useContext(CampaignContext);


  useEffect(() => {
    const foundCompletedCommits: Commit[] = allCommits ? allCommits.filter(c=>c.state ==='Completed') : []
    setCompletedCommits(foundCompletedCommits);
  }, [allCommits]);

  const getInfluencersListStyle = () => {
    return completedCommits?.length > 3
      ? "grid lg:grid-cols-5 max-w-screen grid-cols-4 grid-flow-row gap-2 mb-2"
      : "flex flex-col place-content-center place-items-center gap-2";
  };

  return (
    <React.Fragment>
      <div className="place-content-center place-items-center flex flex-col">
        <Paper className="mt-5 w-11/12 lg:w-4/6 mb-5">
          <div className="flex flex-col place-content-center place-items-center">
            <Typography className="font-bold text-lg">
              Campaign Performance
            </Typography>
          </div>
          <CampaignPerformanceStats
            commits={allCommits}
            campaign={campaign}
          />
        </Paper>

        <Paper className="mt-5 w-11/12 lg:w-4/6 mb-5">
          <div className="pb-2 flex flex-col place-content-center place-items-center">
            <Typography className="text-lg font-bold mb-2 text-center">
              Creators for this Campaign
            </Typography>
            <div className={getInfluencersListStyle()}>
              {completedCommits?.map((commit) => (
                <UserChip id={commit.userId} />
              ))}
            </div>
          </div>
        </Paper>
      </div>
    </React.Fragment>
  );
};

export default CampaignStats;
