import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Campaign from "@socialplug/common/interfaces/campaign";
import React from "react";
import CampaignRow from "./CampaignRow";

const CampaignTable: React.FC<any> = (props) => {
  return (
    <React.Fragment>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" className="font-bold">
                Campaign
              </TableCell>
              <TableCell align="center" className="font-bold">
                Brand
              </TableCell>
              <TableCell align="center" className="font-bold">
                Type
              </TableCell>
              <TableCell align="center" className="font-bold">
                Reward
              </TableCell>
              <TableCell align="center" className="font-bold"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.campaigns.map((campaign: Campaign) => (
              <CampaignRow campaign={campaign} key={campaign.id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default CampaignTable;
