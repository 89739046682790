import { IconButton } from "@material-ui/core";
import { AddAPhoto } from "@material-ui/icons";
import { Campaign } from "@socialplug/common/interfaces/campaign";
import React from "react";
import { Control, useFieldArray } from "react-hook-form";
import ImageUploadField from "./ImageUploadField";

type ImageSelectionGridProps = {
  name: "moodboard";
  control: Control<Campaign>;
} & React.HTMLAttributes<HTMLDivElement>;

interface ImageThumbnailProps {
  image?: { url: string; id: string };
  showUpload?: boolean;
}

const ImageThumbnail: React.FC<ImageThumbnailProps> = ({
  image,
  showUpload,
}) => {
  image = image || { id: "", url: "" };
  const hasImage = image.id && image.id !== "";

  return (
    <div
      className={`rounded-lg relative border-2 bg-white w-24 h-24 min-w-max overflow-hidden`}
    >
      <div
        className="absolute w-full h-full flex items-center justify-evenly hover:opacity-80"
        style={
          hasImage
            ? {
                background: `url(${image.url}) center`,
                backgroundSize: "cover",
              }
            : {}
        }
      >
        {!hasImage && showUpload && (
          <IconButton aria-label="add photo" disabled color="primary">
            <AddAPhoto />
          </IconButton>
        )}
      </div>
    </div>
  );
};

const ImageSelectionGrid: React.FC<ImageSelectionGridProps> = ({
  name,
  control,
  className,
  ...props
}) => {
  const fieldArray = useFieldArray({
    control,
    name,
  });

  const getRemainingThumbnails = () => {
    const thumbs = [];

    while (fieldArray.fields.length + thumbs.length < 5) {
      thumbs.push(
        <ImageThumbnail
          key={`thumb-${fieldArray.fields.length + thumbs.length}`}
        ></ImageThumbnail>
      );
    }

    return thumbs;
  };

  return (
    <div
      className={`grid grid-cols-3 grid-flow-row gap-4 max-w-xs mx-auto ${className}`}
      {...props}
    >
      {fieldArray.fields.map((field, index) => (
        <ImageThumbnail key={field.id} image={field} />
      ))}

      <ImageUploadField
        purpose="CampaignMoodboard"
        id="mb-new-image"
        onImageUploaded={(image) => fieldArray.append(image)}
      >
        <ImageThumbnail showUpload={true} key="mb-new-image" />
      </ImageUploadField>

      {getRemainingThumbnails()}
    </div>
  );
};

export default ImageSelectionGrid;
