import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
import React from "react";

type StringCheckListPropTypes = {
  values: string[];
  selectedValues: string[];
  onSelectValue: (value: string) => void;
  className?: string;
};

const StringCheckList: React.FC<StringCheckListPropTypes> = ({
  values,
  selectedValues,
  onSelectValue,
  className,
  ...props
}) => (
  <List classes={{ root: `max-w-full ${className}` }} {...props}>
    {values.map((value) => (
      <ListItem
        divider
        classes={{
          root: "border-2 max-w-full w-96 rounded mb-2 cursor-pointer",
        }}
        key={value}
        onClick={() => onSelectValue(value)}
      >
        <ListItemIcon classes={{ root: "min-w-min" }}>
          <Checkbox
            classes={{ root: "mr-1" }}
            edge="start"
            color="primary"
            checked={selectedValues.includes(value)}
          />
        </ListItemIcon>
        <Typography>{value}</Typography>
      </ListItem>
    ))}
  </List>
);

export default StringCheckList;
