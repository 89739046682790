import { Button, TextField, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import SendIcon from "@material-ui/icons/Send";
import Campaign from "@socialplug/common/interfaces/campaign";
import Offer from "@socialplug/common/interfaces/offer";
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";

const OfferCard: React.FC<any> = (props) => {
  const campaign: Campaign = props.campaign;
  const offer: Offer = props.offer;
  const user: User = props.user;
  const [declined, setDeclined] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [offerTextField, setOfferTextField] = useState("");
  const [showCounterOffer, setShowCounterOffer] = useState(false);
  const [counterOfferEnabled, setCounterOfferEnabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const [, editOffer] = useApi("POST", "offer", "edit");
  const [, editCommit] = useApi("POST", "commit", "edit");
  const [, createOffer] = useApi("POST", "offer", "create");

  useEffect(() => {
    switch (campaign?.rewardType) {
      case "Money":
        if (parseInt(offerTextField) > 0) {
          setCounterOfferEnabled(true);
          setErrorMessage("");
        } else {
          setCounterOfferEnabled(false);
          setErrorMessage("Enter price of money reward");
        }
        break;
      default:
        break;
    }
  }, [offerTextField, campaign]);

  const onDeclineClick = () => {
    setDeclined(true);
    setShowCounterOffer(true);
  };

  const onAcceptClick = () => {
    try {
      (async () => {
        await editOffer({
          body: {
            offerId: offer?.id,
            changes: {
              accepted: true,
            },
          },
        });
      })();
      setAccepted(true);
    } catch {}
  };

  const onCancelSubmission = () => {
    try {
      (async () => {
        await editOffer({
          body: {
            offerId: offer?.id,
            changes: {
              declined: true,
            },
          },
        });
        await editCommit({
          body: {
            campaignId: campaign?.id,
            changes: {
              state: "Declined",
            },
          },
        });
      })();
    } catch {}
  };

  const makeCounterOffer = () => {
    if (offerTextField !== "" && !errorMessage) {
      try {
        (async () => {
          await createOffer({
            body: {
              campaignId: offer?.campaignId,
              commitId: offer?.commitId,
              offeredToId: offer?.offeredById,
              isCounterOffer: true,
              amount: parseFloat(offerTextField),
              previousOfferId: offer.id,
            },
          });
        })();
        setSuccess(true);
      } catch {}
    }
  };

  return (
    <div className="flex flex-col place-items-center place-content-center">
      <Typography>
        {user.name} has requested £{offer?.amount} to post this
        content.
      </Typography>
      {offer?.lowAmount != null && (
        <Typography>
          The lowest amount they will accept is £{offer?.lowAmount}
        </Typography>
      )}

      <div className="flex flex-row mt-10">
        <Button
          startIcon={<CheckIcon />}
          variant="outlined"
          className="text-blue-500 border-blue-500 mr-2"
          disabled={declined || accepted}
          onClick={() => onAcceptClick()}
        >
          Accept
        </Button>
        <Button
          startIcon={<ClearIcon />}
          variant="outlined"
          className="text-yellow-500 border-yellow-500"
          onClick={() => onDeclineClick()}
          disabled={declined || accepted}
        >
          Counter Offer
        </Button>
      </div>
      {declined && showCounterOffer && (
        <React.Fragment>
          <div className="flex place-content-center place-items-center">
            <Typography className="font-bold text-xl text-black">£</Typography>

            <TextField
              placeholder="Make counter offer"
              className="mt-2 w-full text-xl ml-2"
              type="number"
              disabled={success}
              value={offerTextField}
              onChange={(e) => setOfferTextField(e.target.value)}
            />
          </div>

          <Button
            startIcon={<SendIcon />}
            variant="outlined"
            className="mt-2 text-blue-500 border-blue-500"
            onClick={() => makeCounterOffer()}
            disabled={!counterOfferEnabled || success}
          >
            Send Counter Offer
          </Button>

          <Button
            startIcon={<ClearIcon />}
            variant="outlined"
            onClick={() => onCancelSubmission()}
            className="text-red-500 border-red-500 mt-2"
            disabled={success}
          >
            Cancel Submission
          </Button>
          {errorMessage && (
            <Typography className="text-red-500 text-sm">
              {errorMessage}
            </Typography>
          )}
          {success && (
            <Typography className="text-green-500 text-sm">
              Counter Offer made.
            </Typography>
          )}
        </React.Fragment>
      )}
      {accepted && (
        <Typography className="text-green-500 text-sm">
          Offer accepted.
        </Typography>
      )}
    </div>
  );
};

export default OfferCard;
