import { List, Paper, Typography } from "@material-ui/core";
import Commit from "@socialplug/common/interfaces/commit";
import React, { useContext, useEffect, useState } from "react";
import CommitListItem from "../../../components/commit/CommitListItem";
import CampaignContext from "../../../context/CampaignContext";

const CampaignRequests: React.FC = () => {
  const [commits, setCommits] = useState<Commit[]>();
  const { campaign, allCommits } = useContext(CampaignContext);

  useEffect(() => {
    const foundRequestCommits: Commit[] = allCommits ? allCommits.filter(c=>c.state ==='AwaitingApproval') : []
    setCommits(foundRequestCommits);
  }, [allCommits]);


  return (
    <div className="flex flex-col place-content-center place-items-center">
      <Typography className="mt-2 font-bold text-xl text-center">
        Pending Requests for {campaign?.title}
      </Typography>
      {campaign?.type === "Physical" && (
        <Typography className="text-sm">
          Scan their QR code to approve their request to join
        </Typography>
      )}
      <Typography className="text-sm">
        Click on an creators name to view their details
      </Typography>

      {commits !== undefined && commits?.length > 0 && (
        <List component={Paper} className="w-11/12 lg:w-6/12 pb-0">
          {commits?.map((commit) => (
            <CommitListItem
              key={commit.id}
              commit={commit}
              campaign={campaign}
              state={"AwaitingApproval"}
            />
          ))}
        </List>
      )}
    </div>
  );
};

export default CampaignRequests;
