import { Campaign } from "@socialplug/common/interfaces";
import Commit from "@socialplug/common/interfaces/commit";
import * as React from "react";

export interface CampaignContainer {
  campaign: Campaign | undefined;
  setCampaign: (campaign: Campaign | undefined) => void;
  allCommits: Commit[] | undefined;
  setAllCommits: (allCommits: Commit[] | undefined) => void;
}

const CampaignContext = React.createContext<CampaignContainer>({
  campaign: undefined,
  setCampaign: () => {},
  allCommits: undefined,
  setAllCommits: () => {},
});

export default CampaignContext;
