import { Auth } from "@aws-amplify/auth";
import { Button, TextField, Typography } from "@material-ui/core";
import log from "loglevel";
import React, { useState } from "react";
import { useAlert } from "../../components/alerts/AlertServiceContext";
import LoadingButton from "../../components/LoadingButton";

type AuthMode = "signup" | "signin" | "reset-password-request" | "reset-password-required" | "email-confirmation";

const Authenticate = () => {
  const alert = useAlert();

  const [authenticationMode, setAuthenticationMode] =
    useState<AuthMode>("signin");

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");

  const toggleAuthMode = () => {
    setAuthenticationMode(
      authenticationMode === "signup" ? "signin" : "signup"
    );
  };

  const handleSignup = async () => {
    if (confirmPassword !== password) {
      await alert({
        variant: "info",
        title: "Hold up",
        description: "Those passwords you entered aren't matching up.",
      });
      return
    }
    try {
      await Auth.signUp({ 
        username: email,
        password,
        attributes: {
          'custom:account_type': 'BRAND'
        }
      });
      setAuthenticationMode("email-confirmation")
    } catch (err) {
      log.error("Error during sign up:",err);
      await alert({
        variant: "info",
        title: "Uh Oh!",
        description: err.message,
      });
    }
  };

  const handleLogin = async () => {
    try {
      const response = await Auth.signIn({ username: email, password })
      if(response.attributes['custom:account_type'] === 'CREATOR') {
        Auth.signOut()
        throw new Error('cant log in with creator account')
      }
      window.location.href = "/";
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        setAuthenticationMode("email-confirmation")
      } else if (err.code === "PasswordResetRequiredException") {
        console.log('reset password required')
        setAuthenticationMode("reset-password-required")
      } else {
        log.error("Error during sign in:",err);
        await alert({
          variant: "info",
          title: "Something went wrong!",
          description: "We could not log you in.",
        });

      }
    }
  };

  const handleConfirmEmail = async () => {
    try {
      await Auth.confirmSignUp(email, code);
      if(password && password!==""){
        await Auth.signIn({ username: email, password });
        window.location.href = "/";
      }else{
        setAuthenticationMode("signin")
      }
    } catch (err) {
      log.error("Error during sign up:",err);
      await alert({
        variant: "info",
        title: "Something went wrong!",
        description: "We could not confirm your email at this stage.",
      });
    }
  };

  const handlePasswordResetWithCode = async () => {
    if (confirmPassword !== password) {
      await alert({
        variant: "info",
        title: "Hold up",
        description: "Those passwords you entered aren't matching up.",
      });
      return;
    }
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      if(password && password!==""){
        await Auth.signIn({ username: email, password });
        window.location.href = "/";
      }else {
        setAuthenticationMode("signin")
      }
    } catch (err) {
       log.error("Error during password reset:",err);
        await alert({
          variant: "info",
          title: "Something went wrong!",
          description: "We could not reset password at this time.",
        });
    }
  };

  const requestCode = async () => {
    if(authenticationMode == "reset-password-required" || authenticationMode=="reset-password-request"){
      try {
        setIsLoading(true)
        await Auth.forgotPassword(email);
        setAuthenticationMode("reset-password-required")
      } catch (err) {
        log.error("Error during password reset request:",err);

      }
      setIsLoading(false)
      return
    }else  if(authenticationMode == "email-confirmation"){
      try {
        setIsLoading(true)
        await Auth.resendSignUp(email);
      } catch (err) {
        log.error("Error during resending signup code:",err);
      }
      setIsLoading(false)
      return
    }
  };
  const handleAuthentication = async () => {
    setIsLoading(true);

    if (authenticationMode === "signup") {
      await handleSignup();
    } else if (authenticationMode === "signin")  {
      await handleLogin();
    } else if (authenticationMode === "email-confirmation")  {
      await handleConfirmEmail();
    } else if (authenticationMode === "reset-password-required")  {
      await handlePasswordResetWithCode();
    } else {
      console.log('Unsupported auth mode:'+authenticationMode)
      setAuthenticationMode("signin")
    }

    setIsLoading(false);
  };

  return (
    <div className="flex flex-row place-items-center place-content-center h-screen">
      <div className="flex flex-col place-items-center place-content-center justify-items-center lg:w-1/2">
        <img
          src="/assets/logo.png"
          alt="logo"
          className="mb-2 mt-2 lg:mt-10 lg:mb-10 h-32"
        ></img>
        {["signup", "signin", "reset-password-request"].includes(authenticationMode) && (
            <React.Fragment>
                <TextField
                  onChange={(e) => setEmail(e.target.value)}
                  label="Email Address"
                  variant="outlined"
                  className="w-80 mb-3 mt-4 bg-white"
                />
              </React.Fragment>
          )}
        {["signup", "signin", "reset-password-required"].includes(authenticationMode) && (
            <React.Fragment>
              <TextField
                  onChange={(e) => setPassword(e.target.value)}
                  label="Password"
                  type="password"
                  variant="outlined"
                  className="w-80 mt-4 bg-white"
              />
            </React.Fragment>
        )}
        {["signup", "reset-password-required"].includes(authenticationMode) && (
          <React.Fragment>
            <Typography variant="caption" className="mb-3 text-center">
              Min 8 Characters. Must contain a number and a capital letter.
            </Typography>

            <TextField
              onChange={(e) => setConfirmPassword(e.target.value)}
              label="Confirm Password"
              type="password"
              variant="outlined"
              className="w-80 mt-4 bg-white"
            />
          </React.Fragment>
        )}

        {["signup", "signin"].includes(authenticationMode) && (
            <React.Fragment>
              <Button
                onClick={handleAuthentication}
                variant="outlined"
                color="primary"
                className="mb-4 mt-10 bg-yellow-300 text-black font-bold hover:bg-yellow-300 hover:text-black"
              >
                {authenticationMode === "signup" ? "CREATE ACCOUNT" : "LOGIN"}
              </Button>
              <LoadingButton
                  className="mt-4"
                  loading={isLoading}
                  onClick={()=>setAuthenticationMode("reset-password-request")}
              >
                Forgot Password?
              </LoadingButton>

              <Typography variant="h4" className="text-center">
                {authenticationMode === "signup"
                  ? "Already registered?"
                  : "Not got an account?"}
              </Typography>

              <LoadingButton
                className="mb-4 mt-4 bg-black text-yellow-300 font-bold hover:bg-black hover:text-yellow-300"
                variant="outlined"
                color="primary"
                loading={isLoading}
                onClick={() => toggleAuthMode()}
              >
                {authenticationMode === "signin" ? "CREATE ACCOUNT": "SIGN IN"}
              </LoadingButton>
            </React.Fragment>
        )}
        {["reset-password-request"].includes(authenticationMode) && (
            <React.Fragment>
              <LoadingButton
                  className="mt-4"
                  loading={isLoading}
                  onClick={requestCode}
              >
                Reset Password
              </LoadingButton>
            </React.Fragment>
        )}
        {["reset-password-required", "email-confirmation"].includes(authenticationMode) && (
            <React.Fragment>

              <TextField
                  variant="outlined"
                  label="Verification Code"
                  type="text"
                  className="mt-10"
                  onChange={(e) => setCode(e.target.value)}
              ></TextField>
              <Button
                  onClick={handleAuthentication}
                  variant="outlined"
                  color="primary"
                  className="mb-4 mt-10 bg-yellow-300 text-black font-bold hover:bg-yellow-300 hover:text-black"
              >
                {authenticationMode === "reset-password-required" ? "RESET PASSWORD": "CONFIRM EMAIL"}
              </Button>
              <Typography variant="body1">
                You should have received a code into your inbox. Enter it below to
                proceed.
              </Typography>
              <Typography variant="body1">
                You can request new code by clicking on the button below.
              </Typography>
              <LoadingButton
                  className="mt-4"
                  loading={isLoading}
                  onClick={requestCode}
              >
                Send Another Code
              </LoadingButton>

            </React.Fragment>
        )}
      </div>

      <div className="w-0 display:hidden lg:block lg:w-1/2 mr-4">
        <img
          src="/assets/login_art.png"
          alt="banner"
          className="invisible lg:visible object-cover "
        />
        <Typography className="invisible lg:visible font-bold text-center mt-4 text-2xl">
          Create content and reach thousands of people in a couple of clicks
        </Typography>
      </div>
    </div>
  );
};

export default Authenticate;
