import { Button, TextField, Typography } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useState } from "react";

const InitialOffer: React.FC<any> = (props) => {
  const commit = props.commit;
  const campaign = props.campaign;
  const user = props.user;
  const [offerSent, setOfferSent] = useState(false);
  const [offer, setOffer] = useState<number>();
  const [errorMessage, setErrorMessage] = useState("");
  const [, createOffer] = useApi("POST", "offer", "create");

  const sendOfferOnClick = () => {
    if (offer && offer != null && offer > 0) {
      (async () => {
        try {
          await createOffer({
            body: {
              campaignId: commit?.campaignId,
              commitId: commit?.id,
              offeredToId: commit?.userId,
              isCounterOffer: false,
              amount: offer,
            },
          });
          setOfferSent(true);
        } catch {}
      })();
    } else {
      setErrorMessage("Please enter a value greater than 0");
    }
  };

  const getSuggestedPricing = () => {
    const followers = user?.followers;
    if (followers !== undefined) {
      if (followers < 3000) return "£20-£30";
      else if (followers < 10000) return "£30-£100";
      else if (followers < 25000) return "£100-£180";
      else if (followers < 50000) return "£150-£250";
      else {
        return "£250-£350";
      }
    } else {
      return "Could not find suggested price for this creator.";
    }
  };

  return (
    <React.Fragment>
      <div className="flex flex-col place-items-center place-content-center">
        <Typography className="text-2xl font-bold text-center mb-2 mt-2">
          Create an initial offer for:
        </Typography>
        {commit && campaign && (
          <React.Fragment>
            <div className="place-content-center place-items-center text-center ml-3 mr-3 flex flex-col">
              {campaign?.rewardType === "Money" && (
                <React.Fragment>
                  <Typography>
                    Submit your initial cash offer to this creator.
                  </Typography>
                  <Typography className="text-sm mb-2">
                    Suggested price for this creator: {getSuggestedPricing()}
                  </Typography>
                </React.Fragment>
              )}
            </div>
            <TextField
              label="Your Offer"
              variant="outlined"
              type="number"
              value={offer}
              onChange={(e) => setOffer(parseFloat(e.target.value))}
              className="w-80 mb-2"
            />

            <Button
              className="rounded-lg hover:bg-blue-200"
              variant="outlined"
              startIcon={<SendIcon />}
              onClick={() => sendOfferOnClick()}
              disabled={offerSent}
            >
              Send Offer
            </Button>

            {offerSent && (
              <Typography className="text-green-500">
                Your initial offer has been sent.
              </Typography>
            )}
            {errorMessage && (
              <Typography className="text-red-500">{errorMessage}</Typography>
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default InitialOffer;
