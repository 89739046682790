import Campaign from "@socialplug/common/interfaces/campaign";
import Commit from "@socialplug/common/interfaces/commit";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";
import { Route, useParams } from "react-router";
import CampaignDrawer from "../../components/CampaignDrawer";
import CampaignContext from "../../context/CampaignContext";
import CampaignDetails from "./campaigns/CampaignDetails";
import CampaignFormPage from "./campaigns/CampaignFormPage";
import CampaignRequests from "./campaigns/CampaignRequests";
import CampaignStats from "./campaigns/CampaignStats";
import CampaignSubmissions from "./campaigns/CampaignSubmissions";
import Submission from "./campaigns/Submission";

type CampaignDashboardPageParams = {
  id: string;
};

const CampaignDashboard = () => {
  const { id } = useParams<CampaignDashboardPageParams>();

  const [campaign, setCampaign] = useState<Campaign>();
  const [allCommits, setAllCommits] = useState<Commit[]>();

  const [, getCampaign] = useApi("GET", "campaign", "fetch");
  const [, getCommits] = useApi("GET", "commit", "list");

  const campaignContextProviderValue = {
    campaign,
    setCampaign,
    allCommits,
    setAllCommits,
  };

  useEffect(() => {
    (async () => {
      try {
        const foundCampaign = await getCampaign({
          queryParams: { campaignId: id },
        });
        setCampaign(foundCampaign);
        const foundCommits: Commit[] = await getCommits({
          queryParams: { campaignId: id },
        });
        setAllCommits(foundCommits);
      } catch(e) {console.log("CampaignDashboard useEffect error", e)}
    })();
  }, [getCommits, getCampaign, id]);

  return (
    <CampaignContext.Provider value={campaignContextProviderValue}>
      <div className="max-w-screen">
        <CampaignDrawer campaign={campaign} />
        <div className="lg:pl-72">
          <Route
            path="/campaign/:id/details"
            exact
            component={CampaignDetails}
          />
          <Route
            path="/campaign/:id/requests"
            exact
            component={CampaignRequests}
          />
          <Route
            path="/campaign/:id/submissions/:state"
            exact
            component={CampaignSubmissions}
          />
          <Route
            path="/campaign/:id/submission/:commitId"
            exact
            component={Submission}
          />
          <Route
            path="/campaign/:id/analytics"
            exact
            component={CampaignStats}
          />
          <Route path="/campaign/:id/edit" exact component={CampaignFormPage} />
        </div>
      </div>
    </CampaignContext.Provider>
  );
};

export default CampaignDashboard;
