import { Typography } from "@material-ui/core";
import Campaign from "@socialplug/common/interfaces/campaign";
import { useApi } from "@socialplug/common/utilities/api";
import { compareAsc } from "date-fns";
import React, { useContext, useEffect } from "react";
import CampaignCard from "../../../components/CampaignCard";
import BrandContext from "../../../context/BrandContext";

const Campaigns = () => {
  var url = window.location.pathname;
  const state = url.substring(url.lastIndexOf("/") + 1);

  var campaignsToDisplay: Campaign[] = [];

  const { activeBrand, brandCampaigns } = useContext(BrandContext);
  const brandContext = useContext(BrandContext);
  const [, listCampaigns] = useApi("GET", "campaign", "list");

  useEffect(() => {
    (async () => {
      try {
        const campaignList: Campaign[] = await listCampaigns({
          queryParams: { brandId: activeBrand?.id },
        });
        brandContext.setBrandCampaigns(campaignList);
      } catch {}
    })();
  }, [listCampaigns, activeBrand]);

  if (brandCampaigns !== null && brandCampaigns !== undefined) {
    if (state === "active" && brandCampaigns != null) {
      brandCampaigns.forEach((campaign) => {
        const campaignExpired =
          compareAsc(new Date(campaign.endDate), new Date()) === -1;
        if (!campaignExpired) {
          campaignsToDisplay.push(campaign);
        }
      });
    } else if (state === "expired" && brandCampaigns != null) {
      brandCampaigns.forEach((campaign) => {
        const campaignExpired =
          compareAsc(new Date(campaign.endDate), new Date()) === -1;
        if (campaignExpired) {
          campaignsToDisplay.push(campaign);
        }
      });
    } else {
      campaignsToDisplay = brandCampaigns;
    }
  }

  const getCardLayoutStyle = () => {
    if (campaignsToDisplay.length === 1) {
      return "";
    } else if (campaignsToDisplay.length <= 4) {
      return "grid grid-rows-1 lg:grid-cols-2 lg:grid-flow-row mt-4";
    } else {
      return "grid grid-rows-1 lg:grid-cols-3 lg:grid-flow-row mt-4";
    }
  };

  const getTitle = () => {
    switch (state) {
      case "all":
        return "All Campaigns";
      case "expired":
        return "Expired Campaigns";
      case "active":
        return "Active Campaigns";
    }
  };

  return (
    <div className=" flex flex-col place-content-center place-items-center">
      <Typography className="text-xl font-bold my-2">{getTitle()}</Typography>
      {campaignsToDisplay !== undefined && (
        <React.Fragment>
          {campaignsToDisplay?.length >= 1 && (
            <div className="flex flex-col gap-2 place-items-center place-content-center">
              <div className={getCardLayoutStyle()}>
                {campaignsToDisplay?.map((c) => (
                  <CampaignCard key={c.id} campaign={c} />
                ))}
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default Campaigns;
