import { InputAdornment } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import TextField, { OutlinedTextFieldProps } from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@material-ui/lab/Autocomplete";
import { Campaign } from "@socialplug/common/interfaces/campaign";
import parse from "autosuggest-highlight/parse";
import React, { useEffect, useMemo, useRef, useState } from "react";

const loadScript = (src: string, position: any, id: string) => {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export type AddressType = {
  addressCoordinates?: { type: string; coordinates: number[] };
  addressFormatted?: string;
  addressPlaceId?: string;
};

interface AddressTextFieldProps extends OutlinedTextFieldProps {
  onAddressChanged: (newValue: AddressType) => void;
  helperText?: string;
  errorText?: string;
  error?: any;
  className?: string;
  defaultPlaceId?: string;
}

const AddressTextField: React.FC<AddressTextFieldProps> = ({
  onAddressChanged,
  helperText,
  errorText,
  error,
  className,
  defaultPlaceId,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState<Partial<Campaign> | undefined>(undefined);
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<Array<any>>([]);
  const [fetchingPlace, setFetchingPlace] = useState<boolean>(false);
  const [hasAppliedDefault, setHasAppliedDefault] = useState<boolean>(false);

  const loaded = useRef<boolean>(false);
  const [googleServicesExist, setGoogleServicesExist] = useState<boolean>(
    false
  );

  const autocompleteService = useRef<any>(undefined);
  const placesService = useRef<any>(undefined);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetchData = React.useMemo<any>(() => {
    return (request: any, callback: any) => {
      return autocompleteService.current!.getPlacePredictions(
        request,
        callback
      );
    };
  }, []);

  const fetchByPlaceId = async (placeId: string, callback: any) => {
    placesService.current!.getDetails(
      { placeId, fields: ["geometry.location", "formatted_address"] },
      callback
    );
  };

  const selectedPredictedValue = useMemo<any>(() => {
    return (newValue: any) => {
      if (!newValue) {
        setValue(undefined);
        onAddressChanged({
          addressCoordinates: undefined,
          addressFormatted: undefined,
          addressPlaceId: undefined,
        });
        return;
      }

      setFetchingPlace(true);

      fetchByPlaceId(newValue.place_id, (result: any) => {
        const placeValue: any = {
          addressCoordinates: {
            type: "Point",
            coordinates: [
              result.geometry.location.lng(),
              result.geometry.location.lat(),
            ],
          },
          addressFormatted: result.formatted_address,
          addressPlaceId: newValue.place_id,
        };
        setValue(placeValue);
        onAddressChanged(placeValue);
      });
    };
  }, [onAddressChanged]);

  useEffect(() => {
    let active = true;

    const windowAny = window as any;

    if (!googleServicesExist) {
      const timeout = setInterval(() => {
        if (!autocompleteService.current && windowAny.google) {
          autocompleteService.current = new windowAny.google.maps.places.AutocompleteService();
          placesService.current = new windowAny.google.maps.places.PlacesService(
            document.getElementById("google-maps")
          );

          clearInterval(timeout);
          setGoogleServicesExist(true);
        }
      }, 100);
    }

    if (!autocompleteService.current || !placesService.current) {
      return undefined;
    }

    if (placesService.current && defaultPlaceId && !hasAppliedDefault) {
      selectedPredictedValue({ place_id: defaultPlaceId });
      setHasAppliedDefault(true);
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (inputValue) {
      fetchData({ input: inputValue }, (results: any[]) => {
        if (active) {
          let newOptions: any[] = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      });
    }

    return () => {
      active = false;
    };
  }, [
    value,
    inputValue,
    fetchData,
    googleServicesExist,
    defaultPlaceId,
    fetchingPlace,
    selectedPredictedValue,
    hasAppliedDefault,
  ]);

  if (!value) {
    return (
      <Autocomplete
        id="google-maps"
        classes={{ root: `w-96 mt-8 max-w-full ${className}` }}
        getOptionLabel={(option: any) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          newValue && selectedPredictedValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            label="Campaign Address"
            variant="outlined"
            helperText={error ? errorText : helperText}
            error={error}
            fullWidth
            InputLabelProps={{
              shrink: !!inputValue,
            }}
            InputProps={{
              startAdornment: fetchingPlace ? (
                <InputAdornment position="start">
                  <GroupWorkIcon className="animate-spin" />
                </InputAdornment>
              ) : (
                params.InputProps.startAdornment
              ),
              ...params.InputProps,
            }}
          />
        )}
        renderOption={(option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [
              match.offset,
              match.offset + match.length,
            ])
          );

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    );
  } else {
    return (
      <TextField
        classes={{ root: "w-96 mt-8 max-w-full" }}
        disabled
        label="Campaign Address"
        variant="outlined"
        helperText={error ? errorText : helperText}
        error={error}
        fullWidth
        value={value.addressFormatted}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={() => selectedPredictedValue(undefined)}>
                <CancelIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
};

export default AddressTextField;
