import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Commit from "@socialplug/common/interfaces/commit";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";
import ToChaseCommitsRow from "../../components/admin/ToChaseCommitsRow";
const AdminToChase: React.FC = () => {
  const [commits, setCommits] = useState<Commit[]>([]);
  const [commitsToShow, setCommitsToShow] = useState<Commit[]>();
  const [commitState, setCommitState] = useState("");
  const [, getCommits] = useApi("GET", "admin", "listToChaseCommits");

  useEffect(() => {
    try {
      (async () => {
        const foundCommits = await getCommits();
        setCommits(foundCommits);
        setCommitsToShow(foundCommits);
      })();
    } catch {}
  }, [getCommits]);

  useEffect(() => {
    var newCommitsToShow: Commit[] = [];
    if (commitState !== "") {
      commits.forEach((commit) => {
        if (commit.state === commitState) newCommitsToShow.push(commit);
      });
    } else {
      newCommitsToShow = commits;
    }
    setCommitsToShow(newCommitsToShow);
  }, [commits, commitState]);

  const handleTypeChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    await setCommitState((event.target as HTMLInputElement).value);
  };

  return (
    <React.Fragment>
      {commits !== null && commits !== undefined && commits.length > 0 && (
        <div className="flex flex-col place-content-center place-items-center">
          <Typography className="mt-4 mb-4 text-xl text-center font-bold">
            Commits to chase
          </Typography>
          <FormControl component="fieldset">
            <FormLabel component="legend">Commit State</FormLabel>
            <RadioGroup
              aria-label="State"
              value={commitState}
              name="state"
              onChange={handleTypeChange}
            >
              <div className="flex">
                <FormControlLabel value="" control={<Radio />} label="Any" />
                <FormControlLabel
                  value="AwaitingApproval"
                  control={<Radio />}
                  label="Awaiting Approval"
                />
                <FormControlLabel
                  value="ContentPending"
                  control={<Radio />}
                  label="Content Pending"
                />
                <FormControlLabel
                  value="AwaitingSubmissionApproval"
                  control={<Radio />}
                  label="Awaiting Submission Approval"
                />
                <FormControlLabel
                  value="SubmissionApproved"
                  control={<Radio />}
                  label="Submission Approved"
                />
                <FormControlLabel
                  value="InNegotiation"
                  control={<Radio />}
                  label="In Negotiation"
                />
                <FormControlLabel
                  value="Completed"
                  control={<Radio />}
                  label="Completed"
                />
              </div>
            </RadioGroup>
          </FormControl>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" className="font-bold">
                  Creator
                </TableCell>
                <TableCell align="center" className="font-bold">
                  Campaign
                </TableCell>
                <TableCell align="center" className="font-bold">
                  Commit State
                </TableCell>
                <TableCell align="center" className="font-bold">
                  Last modified
                </TableCell>
                <TableCell align="center" className="font-bold"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {commitsToShow?.map((commit) => (
                <React.Fragment>
                  {commit.publicationStatus !== "Published" &&
                    commit.state !== "Declined" && (
                      <ToChaseCommitsRow commit={commit} />
                    )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </React.Fragment>
  );
};

export default AdminToChase;
