import { Typography } from "@material-ui/core";
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import { useEffect, useState } from "react";
import CompletedAssignedCampaignsTable from "../../components/admin/CompletedAssignedCampaignsTable";
import CreatorTable from "../../components/admin/CreatorTable";

const AdminInfluencers: React.FC = () => {
  const [users, setUsers] = useState<User[]>();
  const [, getAllUsers] = useApi("GET", "admin", "listCreators");

  useEffect(() => {
    (async () => {
      try {
        const foundUsers: User[] = await getAllUsers({
          queryParams: { status: "Waiting" },
        });
        setUsers(foundUsers);
      } catch {}
    })();
  }, [getAllUsers]);

  return (
    <div className="flex flex-col place-content-center place-items-center">
      <Typography className="mt-2 text-2xl text-center font-bold">
        Creators
      </Typography>
      <Typography className="mt-4 text-xl text-center font-bold">
        Creators awaiting approval
      </Typography>
      <CreatorTable users={users} />
      <Typography className="mt-10 text-center text-xl font-bold">
        Creators with completed assigned campaigns
      </Typography>
      <CompletedAssignedCampaignsTable />
    </div>
  );
};

export default AdminInfluencers;
