import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useContext, useEffect, useState } from "react";
import BrandContext from "../../context/BrandContext";

const Settings = () => {
  const [subscriptionLink, setSubscriptionLink] = useState<string>("");
  const { activeBrand } = useContext(BrandContext);
  const [, getSubscriptionManagementLink, subLinkErr] = useApi(
    "POST",
    "financial",
    "create-portal-session"
  );

  useEffect(() => {
    const a = async () => {
      try {
        const res = await getSubscriptionManagementLink({
          queryParams: {
            brandId: activeBrand?.id,
          },
        });

        if (subLinkErr.current) {
          console.log("Could not get Link for Stripe.");
          subLinkErr.current = undefined;
          return;
        }

        console.log(res.url);
        setSubscriptionLink(res.url);
      } catch (e) {
        console.log(e);
      }
    };

    a();
  }, []);

  return (
    <React.Fragment>
      <div className="flex flex-col place-items-center place-content-center">
        <Typography className="text-bold text-3xl mt-5">Settings</Typography>

        <Paper variant="outlined" className="w-4/5 mb-5 mt-8 lg:w-2/5 xl:w-1/5">
          <List>
            <ListItem
              onClick={() => window.open(subscriptionLink)}
              divider
              className="hover:bg-blue-200"
            >
              <ListItemIcon>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText>Your Subscription</ListItemText>
              <ArrowForwardIosIcon />
            </ListItem>
          </List>
        </Paper>
      </div>
    </React.Fragment>
  );
};

export default Settings;
