import { Button, Card, CardContent, TextField } from "@material-ui/core";
import User from "@socialplug/common/interfaces/user";
import { useEffect, useState } from "react";
import { useApi } from "../../../../shared/utilities/api";

const AssignCampaignModal: React.FC<any> = (props) => {
  const user: User = props.user;

  const [assigned, setAssigned] = useState(false);
  const [, assignCampaign] = useApi("POST", "admin", "assignCampaign");
  const [campaignId, setCampaignId] = useState("");

  useEffect(() => {
    if (user.assignedCampaignId !== "") setAssigned(true);
  }, [user]);

  const assignCampaignClick = async () => {
    await assignCampaign({
      body: {
        creatorId: user.id,
        campaignId: campaignId,
      },
    });
    setAssigned(true);
  };

  return (
    <div className="place-content-center place-items-center flex mt-10">
      <Card>
        <CardContent className="flex flex-col">
          <TextField
            id="outlined-basic"
            label="Campaign Id"
            variant="outlined"
            value={campaignId}
            onChange={(e) => setCampaignId(e.target.value)}
          />
          <Button
            disabled={campaignId === "" && !assigned}
            variant="outlined"
            className="mt-2"
            onClick={() => assignCampaignClick()}
          >
            Assign campaign to {user.name}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default AssignCampaignModal;
