import { TableCell, TableRow, Typography } from "@material-ui/core";
import User from "@socialplug/common/interfaces/user";
import { useHistory } from "react-router";

const AllCreatorsRow: React.FC<any> = (props) => {
  const user: User = props.user;

  let history = useHistory();

  const getStatusColor = () => {
    switch (user.status) {
      case "Waiting":
        return "text-yellow-500";
      case "Approved":
        return "text-green-500";
      case "Rejected":
        return "text-red-500";
      default:
        return "";
    }
  };

  const instagramHandle = user.instagramAccountHandle && user.instagramAccountHandle !== '' 
    ? `@${user.instagramAccountHandle}` : ''

  const tiktokHandle = user.tiktokAccountHandle && user.tiktokAccountHandle !== ''
    ? `@${user.tiktokAccountHandle}` : ''

  return (
    <TableRow onClick={() => history.push(`/admin/influencer/${user.id}`)}>
      <TableCell align="center">
        <Typography>{user.name}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>{instagramHandle}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>{tiktokHandle}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>{user.email}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography className={getStatusColor()}>{user.status}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default AllCreatorsRow;
