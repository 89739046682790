import { Button, Typography } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Welcome: React.FC = () => {
  const [step, setStep] = useState(0);

  let history = useHistory();
  useEffect(() => {
    setStep(0);
  }, []);

  const firstScreen = () => {
    return (
      <React.Fragment>
        <img
          src="https://i.imgur.com/W3SwDi5.png"
          alt="welcome"
          className="w-50 h-50 mt-28"
        />
        <Typography className="text-center text-lg">
          Discover brands and get paid to celebrate the products you love
        </Typography>
        <Button
          className=" rounded-lg mt-5"
          endIcon={<ArrowForwardIosIcon />}
          onClick={() => nextButtonOnClick()}
          variant="outlined"
        >
          Next
        </Button>
      </React.Fragment>
    );
  };

  const secondScreen = () => {
    return (
      <React.Fragment>
        <img
          src="https://i.imgur.com/rdYMixf.png"
          alt="welcome"
          className="w-50 h-50 mt-28"
        />
        <Typography className="text-center text-lg">
          Redeem offers both locally and online to receive free and discounted
          products
        </Typography>
        <Button
          className=" rounded-lg mt-5"
          endIcon={<ArrowForwardIosIcon />}
          onClick={() => nextButtonOnClick()}
          variant="outlined"
        >
          Next
        </Button>
      </React.Fragment>
    );
  };

  const pages = [firstScreen, secondScreen];

  const nextButtonOnClick = () => {
    step < pages.length - 1
      ? setStep(step + 1)
      : history.push("/dashboard/campaigns/all");
  };

  return (
    <div className="flex flex-col place-items-center place-content-center ml-2 mr-2">
      <Typography className="text-4xl text-center mt-5">
        <strong>Welcome to SocialPlug</strong>
      </Typography>
      {step === 0 && <>{firstScreen()}</>}
      {step === 1 && <>{secondScreen()}</>}
    </div>
  );
};

export default Welcome;
