import React, { useContext } from "react";
import { Route } from "react-router-dom";
import ResponsiveDrawer from "../../components/ResponsiveDrawer";
import BrandContext from "../../context/BrandContext";
import AdminAllInfluencers from "../admin/AdminAllInfluencers";
import AdminBrands from "../admin/AdminBrands";
import AdminCampaigns from "../admin/AdminCampaigns";
import AdminHome from "../admin/AdminHome";
import AdminInfluencers from "../admin/AdminInfluencers";
import AdminToChase from "../admin/AdminToChase";
import Account from "./account/Account";
import ContentBlock from "./account/ContentBlock";
import Subscription from "./account/Subscription";
import CampaignFormPage from "./campaigns/CampaignFormPage";
import Campaigns from "./campaigns/Campaigns";
import CreateCampaign from "./campaigns/CreateCampaign";
import Home from "./Home";
import InfluencerProfile from "./InfluencerProfile";
import Settings from "./Settings";

const Dashboard = () => {
  const { activeBrand } = useContext(BrandContext);

  return (
    <div className="max-w-screen">
      <ResponsiveDrawer
        brandName={activeBrand?.name || ""}
        admin={activeBrand?.admin || false}
      />

      <div className="lg:pl-72">
        <Route path="/dashboard/admin/stats" exact component={AdminHome} />
        <Route
          path="/dashboard/admin/influencers"
          exact
          component={AdminInfluencers}
        />
        <Route
          path="/dashboard/admin/allInfluencers"
          exact
          component={AdminAllInfluencers}
        />
        <Route path="/dashboard/admin/brands" exact component={AdminBrands} />
        <Route
          path="/dashboard/admin/campaigns"
          exact
          component={AdminCampaigns}
        />
        <Route path="/dashboard/admin/toChase" exact component={AdminToChase} />
        <Route path="/dashboard/home" exact component={Home} />
        <Route path="/dashboard/campaigns/:state" exact component={Campaigns} />
        <Route
          path="/dashboard/campaign/create"
          exact
          component={CreateCampaign}
        />
        <Route
          path="/dashboard/campaign/create/:type"
          exact
          component={CampaignFormPage}
        />
        <Route path="/dashboard/profile" exact component={Account} />
        <Route path="/dashboard/content" exact component={ContentBlock} />
        <Route path="/dashboard/settings" exact component={Settings} />
        <Route
          path="/dashboard/your subscription"
          exact
          component={Subscription}
        />
        <Route
          path="/dashboard/influencer/stats/:uid"
          exact
          component={InfluencerProfile}
        />
      </div>
    </div>
  );
};

export default Dashboard;
