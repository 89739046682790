import Auth from "@aws-amplify/auth";
import { Paper, Typography } from "@material-ui/core";
import Campaign from "@socialplug/common/interfaces/campaign";
import Commit from "@socialplug/common/interfaces/commit";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Offer from "@socialplug/common/interfaces/offer";
import PendingOffersTable from "../../components/home/PendingOffersTable";
import PendingCommitCard from "../../components/PendingCommitCard";
import PendingCommitsTable from "../../components/tables/PendingCommitsTable";
import BrandContext from "../../context/BrandContext";
import UserNotifications from "./account/UserNotifications";

const Home: React.FC = () => {
  const [commits, setCommits] = useState<Commit[]>([]);
  const [offers, setOffers] = useState<Offer[]>([]);
  const [requestCommits, setRequestCommits] = useState<Commit[]>([]);
  const [pendingCommits, setPendingCommits] = useState<Commit[]>([]);
  const [, listCommits] = useApi("GET", "commit", "list");
  const [, listCampaigns] = useApi("GET", "campaign", "list");
  const [, listOffers] = useApi("GET", "offer", "list");

  const brandContext = useContext(BrandContext);
  let history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (brandContext.activeBrand && brandContext.activeBrand != null) {
          const foundCampaigns: Campaign[] = await listCampaigns({
            queryParams: { brandId: brandContext.activeBrand?.id },
          });
          brandContext.setBrandCampaigns(foundCampaigns);
          foundCampaigns.forEach(async (campaign) => {
            const foundCommits: Commit[] = await listCommits({
              queryParams: { campaignId: campaign.id, state: "AwaitingApproval" },
            });
            setRequestCommits((prevState) => prevState.concat(foundCommits));
          });
          foundCampaigns.forEach(async (campaign) => {
            const foundCommits: Commit[] = await listCommits({
              queryParams: {
                campaignId: campaign.id,
                state: "AwaitingSubmissionApproval",
              },
            });
            setPendingCommits((prevState) => prevState.concat(foundCommits));
          });
          foundCampaigns.forEach(async (campaign) => {
            const foundCommits: Commit[] = await listCommits({
              queryParams: {
                campaignId: campaign.id,
              },
            });
            setCommits((prevState) => prevState.concat(foundCommits));
            const foundOffersToBrand: Offer[] = await listOffers({
              queryParams: {
                campaignId: campaign.id,
                toId: user.username,
                accepted: true,
              },
            });
            const foundOffersFromBrand: Offer[] = await listOffers({
              queryParams: {
                campaignId: campaign.id,
                byId: user.username,
                accepted: true,
              },
            });
            const totalOffers: Offer[] =
              foundOffersFromBrand.concat(foundOffersToBrand);
            setOffers((prevState) => prevState.concat(totalOffers));
          });
        }
      } catch {}
    })();
  }, [listCommits, listCampaigns, brandContext.activeBrand]);

  const pendingCommitCardOnClick = (commit: Commit) => {
    history.push(`/campaign/${commit.campaignId}/details`);
  };

  const getTotalSpent = () => {
    var toReturn = 0;
    offers.forEach((offer) => {
      toReturn += offer.amount;
    });
    return toReturn;
  };

  const getCompletedCommits = () => {
    var toReturn = 0;
    if (commits !== undefined) {
      commits?.forEach((commit) => {
        if (commit.state === "Completed") {
          toReturn++;
        }
      });
    }
    return toReturn;
  };

  return (
    <div className="max-w-screen">
      <div className="flex flex-col place-content-center place-items-center mb-10">
        <Typography className="text-xl font-bold mt-2">Dashboard</Typography>

        <div className="flex lg:flex-row flex-col w-11/12">
          <div className="lg:w-1/2">
            <Paper className="mt-5  shadow-xl">
              <Typography className="text-xl font-bold  ml-4">
                Requests to join:
              </Typography>
              <PendingCommitsTable commits={requestCommits} />
            </Paper>
            <Paper className="mt-5 shadow-xl">
              <Typography className="text-xl font-bold ml-4">
                Pending Submissions:
              </Typography>
              {pendingCommits.length > 0 && (
                <div className="grid grid-rows-1 gap-4 lg:grid-cols-3 lg:grid-flow-row mt-4 mb-10 px-2">
                  {pendingCommits.map((commit) => (
                    <React.Fragment>
                      <div className="mb-4">
                        <PendingCommitCard
                          key={commit.id}
                          commit={commit}
                          onClick={() => pendingCommitCardOnClick(commit)}
                        />
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              )}
              {pendingCommits.length === 0 && (
                <Typography className="ml-4">
                  No current pending submissions
                </Typography>
              )}
            </Paper>

            <Paper className="mt-5 shadow-xl">
              <Typography className="text-xl font-bold ml-4">Total Posts:</Typography>
              <Typography className="ml-4">
                {getCompletedCommits()}
              </Typography>
            </Paper>

            <Paper className="mt-5 shadow-xl">
              <Typography className="text-xl font-bold ml-4">
                Pending Offers:
              </Typography>
              <PendingOffersTable />
            </Paper>

            <Paper className="mt-5 shadow-xl">
              <Typography className="text-xl font-bold ml-4">Amount Spent:</Typography>
              <Typography className="ml-4">
                £{getTotalSpent()}
              </Typography>
            </Paper>
          </div>
          <div className="lg:ml-4 lg:w-1/2">

            <Paper className="mt-5  flex flex-col shadow-xl overflow-y-scroll max-h-80">
              <Typography className="font-bold mb-2 ml-4 text-xl">
                Event Log
              </Typography>
              <UserNotifications />
            </Paper>

            <Paper className="mt-5 px-4 shadow-xl">
              <Typography className="font-bold text-xl">Whats new?</Typography>
              <Typography>
                Social plug now enabled Marketers to contract their engaged
                creator community through our inbox feature.
              </Typography>
              <Typography className="text-sm">22 June 2021</Typography>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
