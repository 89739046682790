import { Paper, Typography } from "@material-ui/core";
import Campaign from "@socialplug/common/interfaces/campaign";
import Commit from "@socialplug/common/interfaces/commit";
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import CampaignContext from "../../context/CampaignContext";

const CommitCard: React.FC<any> = (props) => {
  const commit: Commit = props.commit;
  const [user, setUser] = useState<User>();
  const [campaign, setCampaign] = useState<Campaign>();
  const campaignContext = useContext(CampaignContext);
  const [, fetchUser] = useApi("GET", "creator", "fetch");
  const [, fetchCampaign] = useApi("GET", "campaign", "fetch");

  let history = useHistory();

  const redirectToSubmission = async () => {
    await campaignContext.setCampaign(campaign);
    history.push(`/campaign/${campaign?.id}/submission/${commit.id}`);
  };

  useEffect(() => {
    try {
      (async () => {
        const foundUser: User = await fetchUser({
          queryParams: { userId: commit?.userId },
        });
        setUser(foundUser);
        const foundCampaign: Campaign = await fetchCampaign({
          queryParams: { campaignId: commit?.campaignId },
        });
        setCampaign(foundCampaign);
      })();
    } catch {}
  }, [fetchUser, fetchCampaign, commit]);

  return (
    <div className="flex flex-col place-content-center place-items-center">
      {campaign != null && commit != null && (
        <Paper onClick={() => redirectToSubmission()}>
          {commit.postType !== "Video" &&
            commit?.images &&
            commit?.images !== null &&
            commit?.images.length > 0 && (
              <img
                src={commit.images[0].url}
                alt="post"
                className="w-80 h-80"
              />
            )}
          {commit.postType === "Video" &&
            commit?.videos &&
            commit?.videos !== null &&
            commit?.videos.length > 0 && (
              <video controls>
                <source src={commit.videos[0].url} type="video/mp4" />
              </video>
            )}
          <Typography>{campaign?.title}</Typography>
          <Typography>Submitted by {user?.name}</Typography>
        </Paper>
      )}
    </div>
  );
};

export default CommitCard;
