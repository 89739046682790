import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Offer from "@socialplug/common/interfaces/offer";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useContext, useEffect, useState } from "react";
import BrandContext from "../../context/BrandContext";
import PendingOffersTableRow from "./PendingOffersTableRow";

const PendingOffersTable: React.FC = () => {
  const [offers, setOffers] = useState<Offer[]>([]);
  const [, getPendingOffers] = useApi("GET", "offer", "list-brand-pending");
  const { activeBrand } = useContext(BrandContext);

  useEffect(() => {
    try {
      (async () => {
        const foundOffers: Offer[] = await getPendingOffers({
          queryParams: { brandId: activeBrand?.id },
        })
        if(foundOffers) setOffers(foundOffers)
      })();
    } catch {}
  }, [activeBrand, getPendingOffers]);

  return (
    <React.Fragment>
          {offers.length > 0 && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Campaign</TableCell>
                    <TableCell align="center">Creator</TableCell>
                    <TableCell align="center">Offer Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {offers.map((offer) => (
                    <PendingOffersTableRow offer={offer} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
      {offers.length === 0 && (
        <Typography className='ml-4'>No currently pending commits</Typography>
      )}
    </React.Fragment>
  );
};

export default PendingOffersTable;
