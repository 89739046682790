import {
  Avatar,
  Button,
  Card,
  CardContent,
  ListItem,
  ListItemText,
  Modal,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CloseIcon from "@material-ui/icons/Close";
import AccountBox from "@material-ui/icons/AccountBox"
import Campaign from "@socialplug/common/interfaces/campaign";
import Commit from "@socialplug/common/interfaces/commit";
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";
import InfluencerInformation from "../../pages/dashboard/InfluencerInformation";

const CommitListItem: React.FC<any> = (props) => {
  const commit: Commit = props.commit;
  const campaign: Campaign = props.campaign;

  const [user, setUser] = useState<User>();
  const [actionTaken, setActionTaken] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [, getUser] = useApi("GET", "creator", "fetch");
  const [, editCommit] = useApi("POST", "commit", "edit");

  useEffect(() => {
    (async () => {
      try {
        const foundUser: User = await getUser({
          queryParams: { userId: commit.userId },
        });
        setUser(foundUser);
      } catch {}
    })();
  }, [commit, getUser]);

  const onAcceptClick = () => {
    editCommit({
      body: {
        commitId: commit.id,
        changes: { state: "ContentPending" },
      },
    });
    setActionTaken(true);
  };

  const onDeclineClick = () => {
    editCommit({
      body: {
        commitId: commit.id,
        changes: { state: "Declined" },
      },
    });
    setActionTaken(true);
  };

  return (
    <ListItem divider className="gap-2">
      <div
        onClick={() => setShowModal(true)}
        className="flex flex-row place-content-center place-items-center text-center w-3/5 "
      >
        <Avatar src={user?.profileImage?.url} />
        <ListItemText>{user?.name}</ListItemText>
      </div>

      <div className="ml-10 lg:m-0">
        {campaign.type === "Online" && (
          <div className="lg:flex lg:flex-row lg:w-full gap-2">
            <Button
              startIcon={<AccountBox />}
              variant="outlined"
              onClick={() => setShowModal(true)}
              className="rounded-lg  text-blue-500 border-blue-500 ml-2 mb-1 lg:mb-0"
            >
              Profile
            </Button>
            <Button
              startIcon={<CheckIcon />}
              onClick={() => onAcceptClick()}
              className="rounded-lg text-blue-500 border-blue-500 ml-2 mb-1 lg:mb-0"
              variant="outlined"
              disabled={actionTaken}
            >
              Accept
            </Button>
            <Button
              startIcon={<ClearIcon />}
              onClick={() => onDeclineClick()}
              className="rounded-lg text-red-500 border-red-500 ml-2"
              variant="outlined"
              disabled={actionTaken}
            >
              Decline
            </Button>
          </div>
        )}
      </div>
      <Modal
        open={showModal}
        className="overflow-scroll lg:pl-72"
        onClose={() => setShowModal(false)}
        onBackdropClick={() => setShowModal(false)}
      >
        <Card className="m-4 lg:m-24 flex flex-col place-content-center place-items-center">
          <CardContent className="flex max-w-full flex-col place-content-center place-items-center">
            <InfluencerInformation userId={user?.id} />
            <Button
              variant="outlined"
              startIcon={<CloseIcon />}
              className="m-2"
              onClick={() => setShowModal(false)}
            >
              Close
            </Button>
          </CardContent>
        </Card>
      </Modal>
    </ListItem>
  );
};

export default CommitListItem;
