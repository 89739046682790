import { Button, Typography } from "@material-ui/core";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
type RedemptionParams = {
  commit_id: string;
};
const RedemptionScreen: React.FC = () => {
  const { commit_id } = useParams<RedemptionParams>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>();
  const [, redeemCommitOffer] = useApi("POST", "commit", "redeem");

  const [redeemResponse, setRedeemResponse] = useState<any>();

  let history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const redeemOfferResponse = await redeemCommitOffer({
          body: {
            commitId: commit_id,
          },
        });
        if (!redeemOfferResponse) {
          setError(
            "Error redeeming the offer, check you scanned correct code or contact support."
          );
        }
        setRedeemResponse(redeemOfferResponse);
      } catch {
        setError(
          "Unexpected error while processing redemptions, please contact support."
        );
      }
      setLoading(false);
    })();

    return () => {};
  }, []);

  const redirectToCampaign = () => {
    history.push(`/campaign/${redeemResponse?.campaignId}/details`);
  };

  return (
    <div className="flex flex-col place-content-center place-items-center">
      {loading && (
        <Typography
          variant="h4"
          className="text-xl lg:text-xl text-center p-14"
        >
          Please wait while we fetch the data...
        </Typography>
      )}
      {!loading && error && (
        <>
          <Typography
            variant="body1"
            className="text-l lg:text-l text-center p-14"
          >
            Error while processing redemption, offer could be already redeemed
            or expired - please check the code or contact support.
          </Typography>
        </>
      )}
      {!loading && !error && (
        <React.Fragment>
          <img
            src={redeemResponse?.campaignCoverImage?.url}
            alt={redeemResponse?.campaignDescription}
            className="w-40 lg:w-80 mt-10"
          />
          <Typography
            variant="h3"
            className="text-xl lg:text-6xl mb-4 text-center"
          >
            Offer approved for: {redeemResponse?.creator}
          </Typography>
          <Typography variant="h4" className="text-xl lg:text-4xl text-center">
            Thank you for participaing in {redeemResponse?.campaignDescription}{" "}
            campaign for {redeemResponse?.brand}
          </Typography>

          <Button
            className="text-blue-500 border-blue-500 mt-10"
            variant="outlined"
            onClick={() => redirectToCampaign()}
          >
            View Campaign
          </Button>
        </React.Fragment>
      )}
    </div>
  );
};

export default RedemptionScreen;
