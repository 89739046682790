import { Button, Modal, Typography } from "@material-ui/core";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import NotesIcon from "@material-ui/icons/Notes";
import Campaign from "@socialplug/common/interfaces/campaign";
import Commit from "@socialplug/common/interfaces/commit";
import { useApi } from "@socialplug/common/utilities/api";
import Divider from "@uiw/react-divider";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import CampaignDetailsModal from "../../../components/campaign/CampaignDetailsModal";
import CampaignStatistics from "../../../components/campaign/CampaignStatistics";
import PendingCommitCard from "../../../components/PendingCommitCard";
import YesNoDialog from "../../../components/YesNoDialog";
import BrandContext from "../../../context/BrandContext";
import CampaignContext from "../../../context/CampaignContext";

const CampaignDetails = () => {
  const { campaign, allCommits } = useContext(CampaignContext);

  const { activeBrand } = useContext(BrandContext);

  const [pendingCommits, setPendingCommits] = useState<Commit[]>([]);

  const [showCampaignDetails, setShowCampaignDetails] = useState(false);
  const [cancelCampaignClicked, setCancelCampaignClicked] = useState(false);

  const [, editCampaign] = useApi("POST", "campaign", "edit");

  let history = useHistory();

  useEffect(() => {
    const foundPendingCommits: Commit[] = allCommits ? allCommits.filter(c=>c.state ==='AwaitingSubmissionApproval') : []
    setPendingCommits(foundPendingCommits);
  }, [allCommits]);

  const cancelCampaign = async () => {
    const date = new Date();
    const yesterday = new Date(date);
    yesterday.setDate(yesterday.getDate() - 1);

    if (campaign != null) {
      const edittedCampaign: Partial<Campaign> = campaign;
      edittedCampaign.endDate = yesterday;
      await editCampaign({
        body: {
          campaignId: campaign?.id,
          changes: {
            title: edittedCampaign.title,
            type: edittedCampaign.type,
            category: edittedCampaign.category,
            url: edittedCampaign.url,
            callToAction: edittedCampaign.callToAction,
            description: edittedCampaign.description,
            moodboard: edittedCampaign.moodboard,
            rewardType: edittedCampaign.rewardType,
            startDate: edittedCampaign.startDate,
            endDate: edittedCampaign.endDate,
            atHandle: edittedCampaign.atHandle,
            hashtags: edittedCampaign.hashtags,
            coverImage: edittedCampaign.coverImage,
            rules: edittedCampaign.rules,
            captionRules: edittedCampaign.captionRules,
          },
        },
      });
    }

    setCancelCampaignClicked(false);
  };

  const handleModalClose = () => {
    setShowCampaignDetails(false);
  };

  const redirectToSubmission = (commitId: string) => {
    history.push(`/campaign/${campaign?.id}/submission/${commitId}`);
  };

  const getPendingCommitsStyle = () => {
    return pendingCommits.length > 3
      ? "grid grid-rows-1 gap-4 lg:grid-cols-3 lg:grid-flow-row mt-4 mb-10"
      : "flex flex-col place-content-center place-items-center";
  };

  return (
    <div className="place-content-center place-items-center flex flex-col">
      {campaign && (
        <React.Fragment>
          <div className="flex flex-col w-full place-items-center">
            <Typography className="text-xl font-bold mt-2 mb-2 ">
              {campaign.title} Performance
            </Typography>

            <CampaignStatistics campaign={campaign} />

            {pendingCommits?.length > 0 && (
              <React.Fragment>
                <Divider />
                <Typography className="text-xl font-bold">
                  Pending Submissions
                </Typography>
                <div className={getPendingCommitsStyle()}>
                  {pendingCommits?.map((commit) => (
                    <div className="w-11/12 mx-4">
                      <PendingCommitCard
                        commit={commit}
                        onClick={() => redirectToSubmission(commit.id)}
                      />
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}

            <Divider />

            <React.Fragment>
              <div className="flex flex-col place-items-center place-content-center w-96">
                <Button
                  className=" mb-5 w-1/2 ml-4 mr-4 hover:bg-blue-200 rounded-lg"
                  variant="outlined"
                  startIcon={<NotesIcon />}
                  onClick={() => setShowCampaignDetails(!showCampaignDetails)}
                >
                  Show Details
                </Button>

                <Button
                  className="mb-5 w-1/2 ml-4 mr-4 hover:bg-red-200 rounded-lg"
                  variant="outlined"
                  onClick={() => setCancelCampaignClicked(true)}
                  startIcon={<CancelOutlinedIcon />}
                >
                  Cancel Campaign
                </Button>
              </div>
            </React.Fragment>
          </div>
          <Modal
            open={showCampaignDetails}
            className="overflow-scroll lg:pl-72 "
            onClose={() => setShowCampaignDetails(false)}
            onBackdropClick={() => setShowCampaignDetails(false)}
          >
            <CampaignDetailsModal
              campaign={campaign}
              onClose={handleModalClose}
              brand={activeBrand}
            />
          </Modal>
          <YesNoDialog
            open={cancelCampaignClicked}
            title="Cancel campaign?"
            text="Are you sure you wish to cancel this campaign?"
            yesOnClick={() => cancelCampaign()}
            noOnClick={() => setCancelCampaignClicked(false)}
          />
        </React.Fragment>
      )}
      {campaign === null && (
        <React.Fragment>
          <Typography className="text-red-500">
            Error no campaign with that ID found.
          </Typography>
        </React.Fragment>
      )}
    </div>
  );
};

export default CampaignDetails;
