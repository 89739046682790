import { Avatar, Button, Paper, Typography } from "@material-ui/core";
import InstagramIcon from "@material-ui/icons/Instagram";
import LanguageIcon from '@material-ui/icons/Language';
import TikTokIcon from '../../assets/logo-tiktok.svg'
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";

const InfluencerInformation: React.FC<any> = (props) => {
  const userId = props.userId;

  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(true)
  const [, getUser] = useApi("GET", "creator", "fetch");
  const [, listCommits] = useApi("GET", "commit", "list");

  useEffect(() => {
    (async () => {
      try {
        if (userId !== '') {
          const foundUser: User = await getUser({
            queryParams: { userId: userId },
          });
          setUser(foundUser);
        }
      } catch {}
      finally {
        setLoading(false)
      }
    })();
  }, [listCommits, getUser, userId]);

  if(!user) {
    return (
      <React.Fragment>
        <Typography>
          {!loading && "There was an error retrieving the user"}
        </Typography>
      </React.Fragment>
    )
  }

  const viewWebsiteOnClick = () => {
    window.open(user.website)
  }

  const viewInstagramOnClick = () => {
    window.open(
      `https://www.instagram.com/${user.instagramAccountHandle}/`,
      "_blank"
    );
  };

  const viewTiktokOnClick = () => {
    window.open(
      `https://www.tiktok.com/@${user.tiktokAccountHandle}`
    )
  }

  const { imagePortfolio, videoPortfolio } = user
  const imgPortfolioSize = imagePortfolio && imagePortfolio.length < 2 ? imagePortfolio.length : 2
  const imgPortfolioSizeLarge = imagePortfolio && imagePortfolio.length < 3 ? imagePortfolio.length : 3
  const vidPortfolioSizeLarge = videoPortfolio && videoPortfolio.length < 2 ? videoPortfolio.length : 2

  return (
    <React.Fragment>
      <div className="flex flex-col place-content-center w-full place-items-center">
        <Avatar src={user?.profileImage?.url} className="mt-5 w-40 h-40" />
        <Typography className="texl-lg">
          {user.name}
        </Typography>
        <Typography className="texl-lg">
          {user.bio}
        </Typography>

        {user.location  && (
          <Typography className="texl-lg mt-5">
            Location: {user.location}
          </Typography>
        )}

        {user.shippingAddress && (
          <Typography className="texl-lg">
            Shipping Adress:{user.shippingAddress}
          </Typography>
        )}
        
        
        {user.instagramAccountHandle && (
          <Button
            startIcon={<InstagramIcon />}
            variant="outlined"
            onClick={viewInstagramOnClick}
            className="mb-2 mt-5 w-1/2 lg:w-1/4 ml-4 mr-4 hover:bg-blue-200 rounded-lg"
          >
            View Instagram
          </Button>
        )}
        
        {user.tiktokAccountHandle && (
          <Button
          variant="outlined"
          onClick={viewTiktokOnClick}
          className="mb-2 w-1/2 lg:w-1/4 ml-4 mr-4 hover:bg-blue-200 rounded-lg"
        >
          <img src={TikTokIcon} alt='tiktok logo' className="w-5 mr-2"/>
          View TikTok
        </Button>
        )}

        {user.website && user.website !== '' && (
          <Button
            startIcon={<LanguageIcon />}
            variant="outlined"
            onClick={viewWebsiteOnClick}
            className="mb-2 w-1/2 lg:w-1/4 ml-4 mr-4 hover:bg-blue-200 rounded-lg"
          >
            View Portfolio
          </Button>
        )}

        <Paper className="w-11/12 lg:w-8/12 shadow-lg mb-2 flex flex-row justify-items-center">
            <div className="flex flex-col w-1/2 justify-center">
              <Typography className="lg:text-lg font-bold text-center">
                Followers
              </Typography>
              <Typography className="lg:text-lg text-center">
                {user?.followers}
              </Typography>
            </div>

            <div className="w-1/2 justify-center flex flex-col">
              <Typography className="lg:text-lg font-bold text-center mx-4">
                Campaigns Completed
              </Typography>
              <Typography className="lg:text-lg text-center">
                {user?.commitsCompleted}
              </Typography>
            </div>
        </Paper>

        {imagePortfolio && imagePortfolio.length > 0 && (
          <Paper className='flex flex-col place-content-center place-items-center mt-5 w-11/12 lg:w-8/12'>
            <Typography className='mt-4 text-2xl font-bold self-center ml-4 mr-4'>Image Portfolio</Typography>
            <div className={`grid  grid-cols-${imgPortfolioSize} gap-4 lg:grid-cols-${imgPortfolioSizeLarge} mt-4 mb-10 ml-4 mr-4 justify-items-center place-content-between`}>
              {imagePortfolio.map((image) => 
                <Paper key={image.id} className={`flex p-2 my-5 w-${imagePortfolio.length === 1 ? "4/6" : "full"} lg:w-${imagePortfolio.length === 1 ? "2/6" : "1/2"} overflow-hidden shadow-lg`}> 
                  <img alt={image.id} src={image.url} />
                </Paper>
              )}
            </div>
          </Paper>
        )}

        {videoPortfolio && videoPortfolio.length > 0 && (
          <Paper className='flex flex-col place-content-center place-items-center m-4 p-4 lg:w-8/12'>
            <Typography className='mt-4 text-2xl font-bold self-center mx-4'>Video Portfolio</Typography>
            <div className={`grid  grid-cols-1 gap-4 lg:grid-cols-${vidPortfolioSizeLarge} mt-4 mb-10 ml-4 mr-4 min-w-full justify-items-center place-content-between`}>
              {videoPortfolio.map((video) => 
                <div key={video.id} className="flex w-60 h-48 lg:w-96 lg:h-64 shadow-md"> 
                  <video className="h-full w-full" controls>
                    <source src={video.url} type="video/mp4" />
                  </video>
                </div>
              )}
            </div>
          </Paper>
        )}
      </div>
    </React.Fragment>
  );
};

export default InfluencerInformation;
