import { TextField } from "@material-ui/core";
import React from "react";

const FormTextField: React.FC<any> = ({
  inputProps,
  helperText,
  errorText,
  error,
  ...props
}) => (
  <TextField
    error={!!error}
    helperText={error ? errorText : helperText}
    variant="outlined"
    className="w-96 mt-8 max-w-full"
    inputProps={inputProps}
    {...props}
  />
);

export default FormTextField;
