import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { Campaign } from "@socialplug/common/interfaces";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";
import CampaignTable from "../../components/admin/CampaignTable";

const AdminCampaigns: React.FC<any> = () => {
  const [campaignType, setCampaignType] = useState<string>("");
  const [rewardType, setRewardType] = useState<string>("");
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [, listCampaigns] = useApi("GET", "admin", "listCampaigns");

  useEffect(() => {
    (async () => {
      const foundCampaigns: Campaign[] = await listCampaigns({
        queryParams: { type: campaignType },
      });
      setCampaigns(foundCampaigns);
    })();
  }, [listCampaigns, campaignType]);

  const handleTypeChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    await setCampaignType((event.target as HTMLInputElement).value);
    const foundCampaigns: Campaign[] = await listCampaigns({
      queryParams: {
        rewardType: rewardType,
        type: (event.target as HTMLInputElement).value,
      },
    });
    setCampaigns(foundCampaigns);
  };

  const handleRewardChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRewardType((event.target as HTMLInputElement).value);
    const foundCampaigns: Campaign[] = await listCampaigns({
      queryParams: {
        rewardType: (event.target as HTMLInputElement).value,
        type: campaignType,
      },
    });
    setCampaigns(foundCampaigns);
  };

  return (
    <div className="flex flex-col place-content-center place-items-center">
      <Typography className="mt-4 mb-4 text-xl text-center font-bold">
        Campaigns
      </Typography>
      <FormControl component="fieldset">
        <FormLabel component="legend">Campaign Type</FormLabel>
        <RadioGroup
          aria-label="Type"
          value={campaignType}
          name="type"
          onChange={handleTypeChange}
        >
          <div className="flex">
            <FormControlLabel value="" control={<Radio />} label="Both" />
            <FormControlLabel
              value="Online"
              control={<Radio />}
              label="Online"
            />
            <FormControlLabel
              value="Physical"
              control={<Radio />}
              label="Physical"
            />
          </div>
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset">
        <FormLabel component="legend">Reward Type</FormLabel>
        <RadioGroup
          aria-label="Type"
          value={rewardType}
          name="Reward type"
          onChange={handleRewardChange}
        >
          <div className="flex">
            <FormControlLabel value="" control={<Radio />} label="All" />
            <FormControlLabel value="Money" control={<Radio />} label="Money" />
            <FormControlLabel
              value="Discount"
              control={<Radio />}
              label="Discount"
            />
            <FormControlLabel value="Gift" control={<Radio />} label="Gift" />
          </div>
        </RadioGroup>
      </FormControl>

      <CampaignTable campaigns={campaigns} />
    </div>
  );
};

export default AdminCampaigns;
