import { Avatar, Chip } from "@material-ui/core";
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const UserChip: React.FC<any> = (props) => {
  const [user, setUser] = useState<User>();
  const [, fetchUser] = useApi("GET", "creator", "fetch");

  let history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        if (props.id && props.id !== null) {
          const foundUser = await fetchUser({
            queryParams: { userId: props.id },
          });
          setUser(foundUser);
        }
      } catch {}
    })();
  }, [fetchUser, props.id]);

  const onClick = () => {
    history.push(`/dashboard/influencer/stats/${props.id}`);
  };

  return (
    <div onClick={() => onClick()}>
      <Chip
        avatar={<Avatar src={user?.profileImage?.url} />}
        label={user?.name}
        variant="outlined"
      />
    </div>
  );
};

export default UserChip;
