import { Typography } from "@material-ui/core";
import Brand from "@socialplug/common/interfaces/brand";
import { useApi } from "@socialplug/common/utilities/api";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAlert } from "./alerts/AlertServiceContext";
import FormTextField from "./forms/FormTextField";
import LoadingButton from "./LoadingButton";

const Stripe = (window as any)["Stripe"];
const stripe = Stripe(process.env.REACT_APP_STRIPE_KEY);
const phoneRegex =
  /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/;
const instaHandleReg =
  /(?:@)([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/;
const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

const OnboardingCarousel: React.FC = () => {
  const alert = useAlert();

  const [isCreatingBrand, createBrand, createBrandErr] = useApi(
    "POST",
    "brand",
    "create"
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Brand>();

  const [, createStripeSession] = useApi("POST", "financial", "create-session");

  useEffect(() => {
    if (createBrandErr.current) {
      alert({
        variant: "info",
        title: "Couldn't create Brand",
        description:
          "We couldn't create the brand for some reason. Contact support.",
      });

      createBrandErr.current = undefined;
    }
  }, [alert, createBrandErr]);

  const onSubmit = async (data: Partial<Brand>) => {
    try {
      const newBrand: Brand = await createBrand({
        body: {
          name: data.name,
          instagramUsername: data.instagramUsername,
          phone: data.phone,
          //use cognito email address when creating a brand
          // emailAddress: data.emailAddress,
        },
      });

      if (!newBrand.admin) {
        const stripeSession = await createStripeSession({
          body: {
            priceId: process.env.REACT_APP_STRIPE_PRICE_ID,
            brandId: newBrand.id,
          },
        });

        stripe.redirectToCheckout({
          sessionId: stripeSession.sessionId,
        });
      } else {
        window.location.href = "/dashboard/campaigns/active";
      }
    } catch (e) {}
  };

  const renderRegisterBrandNameInstagram = (isCreatingBrand: boolean) => {
    return (
      <div className="m-auto flex-col flex place-items-center place-content-center justify-items-center">
        <img
          src="https://i.imgur.com/X0DXsu4.png"
          alt="banner"
          className="w-60 lg:w-2/5 mt-4"
        />

        <Typography variant="h2" className="lg:text-6xl mb-4 text-center">
          Welcome, lets get you plugged!
        </Typography>

        <Typography
          variant="h4"
          className="lg:text-4xl mb-8 lg:mb-20 text-center"
        >
          We just need a little more information to get started.
        </Typography>

        <form
          className="flex flex-col place-content-center place-items-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="ml-2 mr-2 flex flex-col place-items-center">
            <div className="mt-2 mb-5 flex flex-col place-items-center place-content-center">
              <FormTextField
                label="Brand Name"
                variant="outlined"
                className="w-80"
                helperText="This will be the name that everyone will see."
                required
                inputProps={register("name", { required: true })}
              />
            </div>

            <div className="mb-5 flex flex-col place-items-center place-content-center">
              <FormTextField
                label="Instagram handle"
                helperText="Your instagram account handle"
                errorText="You must include the @"
                variant="outlined"
                required
                error={errors.instagramUsername}
                className="w-80"
                InputProps={register("instagramUsername", {
                  required: true,
                  pattern: instaHandleReg,
                })}
              />
            </div>

            {/*<div className="mb-5 flex flex-col place-items-center place-content-center">*/}
            {/*  <FormTextField*/}
            {/*    error={errors.emailAddress}*/}
            {/*    label="Email address"*/}
            {/*    helperText="Your email address"*/}
            {/*    variant="outlined"*/}
            {/*    errorText="Invalid email format"*/}
            {/*    required*/}
            {/*    className="w-80"*/}
            {/*    inputProps={register("emailAddress", {*/}
            {/*      required: true,*/}
            {/*      pattern: emailRegex,*/}
            {/*    })}*/}
            {/*  />*/}
            {/*</div>*/}

            <div className="mb-5 flex flex-col place-items-center place-content-center">
              <FormTextField
                error={errors.phone}
                label="Primary contact number"
                helperText="Just incase we need to call"
                variant="outlined"
                required
                className="w-80"
                inputProps={register("phone", {
                  required: true,
                  pattern: phoneRegex,
                })}
              />
            </div>
          </div>

          <LoadingButton
            variant="outlined"
            color="primary"
            className="mt-12 mb-6 uppercase"
            loading={isCreatingBrand}
            type="submit"
          >
            NEXT STEP
          </LoadingButton>
        </form>
      </div>
    );
  };

  return (
    <div className="flex flex-col place-items-center place-content-center">
      {renderRegisterBrandNameInstagram(isCreatingBrand)}
    </div>
  );
};

export default OnboardingCarousel;
