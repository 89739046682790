import { Avatar, TableCell, TableRow, Typography } from "@material-ui/core";
import Campaign from "@socialplug/common/interfaces/campaign";
import Commit from "@socialplug/common/interfaces/commit";
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

const PendingCommitsTableRow: React.FC<any> = (props) => {
  const commit: Commit = props.commit;
  const [user, setUser] = useState<User>();
  const [campaign, setCampaign] = useState<Campaign>();
  const [, fetchUser] = useApi("GET", "creator", "fetch");
  const [, fetchCampaign] = useApi("GET", "campaign", "fetch");

  let history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const foundUser: User = await fetchUser({
          queryParams: { userId: commit.userId },
        });
        setUser(foundUser);
        const foundCampaign: Campaign = await fetchCampaign({
          queryParams: { campaignId: commit.campaignId },
        });
        setCampaign(foundCampaign);
      } catch {}
    })();
  }, [commit, fetchUser, fetchCampaign]);

  const redirectToCampaignRequests = () => {
    history.push(`/campaign/${commit.campaignId}/requests`);
  };

  return (
    <React.Fragment>
      {campaign !== undefined && user !== undefined && (
        <TableRow onClick={() => redirectToCampaignRequests()}>
          <TableCell align="center">
              
              <div className="ml-2 flex flex-row gap-2 place-content-center place-items-center">
              <Avatar src={user?.profileImage?.url} />
                <Typography>{user?.name}</Typography>
              </div>
          </TableCell>
          <TableCell align="center">{campaign?.title}</TableCell>
          <TableCell align="center">{user?.followers}</TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

export default PendingCommitsTableRow;
