import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import PlatformStats from "@socialplug/common/interfaces/platformStats";
import { useApi } from "@socialplug/common/utilities/api";
import { useEffect, useState } from "react";

const AdminHome = () => {
  const [stats, setStats] = useState<PlatformStats>();
  const [, getPlatformStats] = useApi("GET", "admin", "getPlatformStats");

  useEffect(() => {
    (async () => {
      try {
        const foundStats: PlatformStats = await getPlatformStats();
        setStats(foundStats);
      } catch {}
    })();
  }, [getPlatformStats]);

  return (
    <div className="flex flex-col place-content-center place-items-center">
      <Typography className="mt-2 text-2xl text-center font-bold">
        Platform Statistics
      </Typography>

      <TableContainer component={Paper} className="lg:w-1/3 w-2/3">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">Brands Signed Up</TableCell>
              <TableCell align="center">{stats?.brandSignUps}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">Creators Signed Up</TableCell>
              <TableCell align="center">{stats?.influencers}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">Campaigns Completed</TableCell>
              <TableCell align="center">{stats?.campaigns}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">Content Created</TableCell>
              <TableCell align="center">{stats?.contentCreated}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AdminHome;
