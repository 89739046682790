import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Commit from "@socialplug/common/interfaces/commit";
import React from "react";
import PendingCommitsTableRow from "./PendingCommitsTableRow";

const PendingCommitsTable: React.FC<any> = (props) => {
  const commits: Commit[] = props.commits;

  return (
    <React.Fragment>
      {commits.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Creator</TableCell>
                <TableCell align="center">Campaign</TableCell>
                <TableCell align="center">Followers</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {commits?.map((commit: Commit) => (
                <PendingCommitsTableRow commit={commit} key={commit.id} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {commits.length === 0 && (
        <Typography className="ml-4">No pending requests</Typography>
      )}
    </React.Fragment>
  );
};

export default PendingCommitsTable;
