import { TableCell, TableRow, Typography } from "@material-ui/core";
import Brand from "@socialplug/common/interfaces/brand";

const BrandRow: React.FC<any> = (props) => {
  const brand: Brand = props.brand;

  const getSubStatusColor = () => {
    switch (brand.subscriptionStatus) {
      case "Active":
        return "text-green-500";
      case "Inactive":
        return "text-red-500";
      default:
        return "";
    }
  };

  return (
    <TableRow>
      <TableCell align="center">
        <Typography>{brand.name}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>{brand.instagramUsername}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>{brand.emailAddress}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>{brand.phone}</Typography>
      </TableCell>
      <TableCell align="center" className={getSubStatusColor()}>
        {brand.subscriptionStatus}
      </TableCell>
    </TableRow>
  );
};

export default BrandRow;
