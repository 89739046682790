import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Commit from "@socialplug/common/interfaces/commit";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import PendingCommitCard from "../../../components/PendingCommitCard";
import AwaitingSubmissionRow from "../../../components/tables/AwaitingSubmissionRow";
import CampaignContext from "../../../context/CampaignContext";

type CampaignSubmissionsPageProps = {
  id: string;
  state: string;
};

const CampaignSubmissions = () => {
  const { campaign, allCommits } = useContext(CampaignContext);

  const [foundCommits, setFoundCommits] = useState<Commit[]>([]);
  const [title, setTitle] = useState<string>();
  const { id, state } = useParams<CampaignSubmissionsPageProps>();

  let history = useHistory();

  useEffect(() => {
    switch (state) {
      case "AwaitingSubmissionApproval":
        setTitle("Pending");
        break;
      case "ContentPending":
        setTitle("Awaiting");
        break;
      case "Completed":
        setTitle("Completed");
        break;
      case "Declined":
        setTitle("Declined");
        break;
      case "inNegotiation":
        setTitle("In Negotiation");
        break;
    }

  }, [id, state]);


  useEffect(() => {
    (async () => {
      try {
        if (state !== "inNegotiation") {
          const commits: Commit[] = allCommits ? allCommits.filter(c=>c.state ===state) : []
          setFoundCommits(commits);
        } else {
          const approvedCommits: Commit[] = allCommits ? allCommits.filter(c=>c.state ==='SubmissionApproved') : []
          const inNegotiationCommits: Commit[] = allCommits ? allCommits.filter(c=>c.state ==='InNegotiation') : []
          setFoundCommits(approvedCommits.concat(inNegotiationCommits));
        }
      } catch {}
    })();
  }, [state, allCommits]);

  const redirectToSubmission = (commit: Commit) => {
    history.push(`/campaign/${commit.campaignId}/submission/${commit.id}`);
  };

  return (
    <React.Fragment>
      {campaign !== undefined && (
        <div className="flex flex-col place-items-center place-content-center">
          <React.Fragment>
            <Typography className="text-xl font-bold">
              {title} Submissions
            </Typography>
            {state !== "ContentPending" && (
              <div className="grid grid-rows-1 gap-4 lg:grid-cols-3 lg:grid-flow-row mt-4 mb-10 ml-4 mr-4">
                {foundCommits?.map((commit) => (
                  <React.Fragment key={commit.id}>
                    <PendingCommitCard
                      commit={commit}
                      onClick={() => redirectToSubmission(commit)}
                    />
                  </React.Fragment>
                ))}
              </div>
            )}
            {state === "ContentPending" && (
              <div className="flex flex-col place-content-center place-items-center">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          className="font-bold"
                        ></TableCell>
                        <TableCell align="center" className="font-bold">
                          Name
                        </TableCell>
                        <TableCell align="center" className="font-bold">
                          Instagram Handle
                        </TableCell>
                        <TableCell align="center" className="font-bold">
                          TikTok Handle
                        </TableCell>
                        <TableCell align="center" className="font-bold">
                          Email
                        </TableCell>
                        <TableCell align="center" className="font-bold">
                          Followers
                        </TableCell>
                        <TableCell align="center" className="font-bold">
                          Date
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {foundCommits.map((commit) => (
                        <AwaitingSubmissionRow commit={commit} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </React.Fragment>
        </div>
      )}
      {campaign === undefined && (
        <Typography className="text-red-500 text-xl">
          Error, no campaign with that ID found.
        </Typography>
      )}
    </React.Fragment>
  );
};

export default CampaignSubmissions;
