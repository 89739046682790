import { Avatar, Paper, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import Campaign from "@socialplug/common/interfaces/campaign";
import Commit from "@socialplug/common/interfaces/commit";
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useContext, useEffect, useState } from "react";
import CampaignContext from "../context/CampaignContext";

const PendingCommitCard: React.FC<any> = (props) => {
  const commit: Commit = props.commit;
  const [commitCampaign, setCommitCampaign] = useState<Campaign>()
  const { campaign } = useContext(CampaignContext);
  const [user, setUser] = useState<User>();
  const [, getCampaign] = useApi("GET", "campaign", "fetch");
  const [, getUser] = useApi("GET", "creator", "fetch");

  useEffect(() => {
    void (async () => {
      if (campaign === undefined) {
        const foundCampaign: Campaign = await getCampaign({
          queryParams: { campaignId: commit.campaignId },
        })
        setCommitCampaign(foundCampaign)
      } else {
        setCommitCampaign(campaign)
      }
    })()
  },[])

  useEffect(() => {
    (async () => {
      try {
        if (commit && commit != null) {
          const foundUser: User = await getUser({
            queryParams: { userId: commit.userId },
          });
          setUser(foundUser);
        }
      } catch {}
    })();
  }, [commit, getUser]);

  if(!commitCampaign){
    return (<React.Fragment></React.Fragment>)
  }

  return (
    <React.Fragment>
      <Paper
        onClick={() => props.onClick()}
        className="place-content-center place-items-center flex flex-col"
      >
        <div className="flex flex-row m-2">
          <Avatar src={user?.profileImage?.url} className="mr-2" />
          <div>
            <Typography className="font-bold">
              {user?.name}
            </Typography>
            <Typography>Campaign: {commitCampaign.title}</Typography>
            {commit.state === "Completed" && (
              <React.Fragment>
                {commit.publicationStatus === "NotPublished" ||
                  (commit.publicationStatus === "AwaitingManualPublication" && (
                    <div>
                      <ClearIcon className="fil-current text-red-500" />
                      <Typography className="text-red-500">
                        Content not yet published
                      </Typography>
                    </div>
                  ))}
                {commit.publicationStatus === "Published" && (
                  <div className="flex">
                    <CheckIcon className="fill-current text-green-500" />
                    <Typography className="text-green-500">
                      Content published
                    </Typography>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
        {commit.postType !== "Video" &&
          commit?.images &&
          commit?.images !== null &&
          commit?.images.length > 0 && (
            <div className="flex flex-col bg-gray-400 place-items-center place-content-center">
              <img
                src={commit?.images[0].url}
                alt="post submission"
                className="lg:max-w-xs w-full"
              />
            </div>
          )}
        {commit.postType === "Video" &&
          commit?.videos &&
          commit?.videos !== null &&
          commit?.videos.length > 0 && (
            <video controls>
              <source src={commit.videos[0].url} type="video/mp4" />
            </video>
          )}
        {commit.state === "Completed" ||
          commit.state === "AwaitingSubmissionApproval" ||
          (commit.state === "InNegotiation" && (
            <div className="m-2">
              <Typography className="font-bold">Post Description:</Typography>
              <Typography>{commit.postDescription}</Typography>
            </div>
          ))}
      </Paper>
    </React.Fragment>
  );
};

export default PendingCommitCard;
