import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";

const YesNoDialog: React.FC<any> = (props) => {
  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.noOnClick} className="p-5">
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContentText className="ml-5 mr-5">
          {props.text}
        </DialogContentText>
        <DialogActions>
          <Button onClick={props.yesOnClick} className="text-blue-500">
            Yes
          </Button>
          <Button onClick={props.noOnClick} className="text-red-500">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default YesNoDialog;
