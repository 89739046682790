import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import User from "@socialplug/common/interfaces/user";
import React, { useEffect, useState } from "react";
import CreatorTableRow from "./CreatorTableRow";

const CreatorTable: React.FC<any> = (props) => {
  const users: User[] = props.users;

  const [numberOfCreators, setNumberOfCreators] = useState(0);

  useEffect(() => {
    users?.forEach((user) => {
      if (user.assignedCampaignId === "")
        setNumberOfCreators((prevState) => prevState + 1);
    });
  }, [users]);

  return (
    <React.Fragment>
      {numberOfCreators > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Creator</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((user: User) => (
                <React.Fragment>
                  {user.assignedCampaignId === "" && (
                    <CreatorTableRow user={user} key={user.id} />
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {numberOfCreators === 0 && (
        <Typography>No creators currently in waiting list</Typography>
      )}
    </React.Fragment>
  );
};

export default CreatorTable;
