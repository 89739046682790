import { Typography } from "@material-ui/core";
import Commit from "@socialplug/common/interfaces/commit";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useContext, useEffect, useState } from "react";
import CommitCard from "../../../components/commit/CommitCard";
import BrandContext from "../../../context/BrandContext";

const ContentBlock: React.FC = () => {
  const [commits, setCommits] = useState<Commit[]>([]);
  const { activeBrand, brandCampaigns } = useContext(BrandContext);
  const [, listCommits] = useApi("GET", "commit", "list");

  useEffect(() => {
    (async () => {
      try {
        brandCampaigns?.forEach(async (campaign) => {
          const foundCommits: Commit[] = await listCommits({
            queryParams: {
              campaignId: campaign.id,
              state: "Completed",
            },
          });
          setCommits((prevState) => prevState.concat(foundCommits));
        });
      } catch {}
    })();
  }, [listCommits, activeBrand]);

  const getCardLayoutStyle = () => {
    if (commits.length === 1) {
      return "";
    } else if (commits.length <= 4) {
      return "grid grid-rows-1 lg:grid-cols-2 lg:grid-flow-row mt-4 lg:gap-4";
    } else {
      return "grid grid-rows-1 lg:grid-cols-4 lg:grid-flow-row mt-4 lg:gap-4";
    }
  };

  return (
    <div className="flex flex-col place-content-center place-items-center">
      <Typography className="text-xl font-bold mt-2">Your Content</Typography>
      <div className={getCardLayoutStyle()}>
        {commits.map((commit) => (
          <CommitCard commit={commit} />
        ))}
      </div>
    </div>
  );
};

export default ContentBlock;
