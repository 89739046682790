import Button from "@material-ui/core/Button";
import GroupWorkIcon from "@material-ui/icons/GroupWork";

const LoadingButton = ({ loading, ...props }: any) => (
  <Button
    variant={props.variant || "outlined"}
    color={props.color || "primary"}
    disabled={loading || props.disabled}
    endIcon={loading ? <GroupWorkIcon className="animate-spin" /> : null}
    {...props}
  />
);

export default LoadingButton;
