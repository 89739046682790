import { Button, Paper, Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BrandContext from "../../../context/BrandContext";

const CreateCampaign = () => {
  const [campaignType, setCampaignType] = useState<string>("");
  const { activeBrand } = useContext(BrandContext);

  let history = useHistory();

  useEffect(() => {
    setCampaignType("");
  }, []);

  const activeButtonStyle = { backgroundColor: "#0063cc", color: "white" };

  const getCampaignButtonStyle = (buttonText: string) => {
    return buttonText === campaignType ? activeButtonStyle : {};
  };

  const getConfirmButtonStyle = () => {
    return campaignType === "" ? {} : activeButtonStyle;
  };

  const onCreateCampaignClick = () => {
    if (campaignType !== "") {
      campaignType === "Physical"
        ? history.push("/dashboard/campaign/create/Physical")
        : history.push("/dashboard/campaign/create/Online");
    }
  };

  return (
    <React.Fragment>
      <div className="m-auto flex flex-col place-items-center place-content-center justify-items-center ml-2 mr-2">
        <img
          src="https://i.imgur.com/QtQxLDI.png"
          alt="logo"
          className="w-60 lg:w-96 mt-10"
        />
        <Typography className="text-xl lg:text-2xl text-center lg:w-1/2">
          Discover a goldmine of creators waiting to create thump-stopping
          content for your brand. Review your submissions and decide to revise,
          download or post to their instagram feed with our intuitive insights
        </Typography>

        <div className="mt-10 m-auto flex flex-col place-items-center place-content-center justify-items-center">
          <Typography className="text-center text-xl">
            Where can creators claim your offer?
          </Typography>

          <Paper
            variant="outlined"
            className="flex flex-col place-items-center w-4/6 mt-5"
          >
            <Button
              variant="outlined"
              onClick={() => setCampaignType("Physical")}
              style={getCampaignButtonStyle("Physical")}
              className="mt-2 mb-2 w-40 rounded-xl"
            >
              In Person
            </Button>

            <Button
              variant="outlined"
              onClick={() => setCampaignType("Online")}
              style={getCampaignButtonStyle("Online")}
              className="mb-10 w-40 rounded-xl"
            >
              Online
            </Button>

            <Button
              variant="outlined"
              color="primary"
              endIcon={<ChevronRightIcon />}
              style={getConfirmButtonStyle()}
              onClick={() => onCreateCampaignClick()}
              className="mb-4 w-40 rounded-xl"
            >
              LETS GO
            </Button>
          </Paper>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateCampaign;
