import SaveIcon from "@material-ui/icons/Save";
import Brand from "@socialplug/common/interfaces/brand";
import { useApi } from "@socialplug/common/utilities/api";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import FormTextField from "../../../components/forms/FormTextField";
import LoadingButton from "../../../components/LoadingButton";
import BrandContext from "../../../context/BrandContext";

const phoneRegex =
  /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/;
const instaRegex =
  /(?:@)([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/;

const Account = () => {
  const { activeBrand } = useContext(BrandContext);
  const [isSavingBrand, editBrand] = useApi("POST", "brand", "edit");

  let history = useHistory();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Brand>({
    defaultValues: {},
  });

  useEffect(() => {
    (async () => {
      reset(activeBrand);
    })();
  }, [activeBrand, reset]);

  const onSubmit = async (data: Partial<Brand>) => {
    try {
      if (activeBrand) {
        await editBrand({
          body: {
            brandId: activeBrand.id,
            changes: data,
          },
        });
        history.push("/dashboard/home");
      }
    } catch {}
  };

  return (
    <div className="flex flex-col place-items-center place-content-center">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col place-items-center place-content-center"
      >
        <FormTextField
          error={errors.name}
          helperText="Your Brand Name"
          variant="outlined"
          className="w-80 mb-2 mt-10"
          inputProps={register("name", { required: true })}
        />
        <FormTextField
          error={errors.instagramUsername}
          helperText="Your Instagram Handle"
          variant="outlined"
          className="w-80 mb-2"
          inputProps={register("instagramUsername", {
            required: true,
            pattern: instaRegex,
          })}
          errorText="Invalid instagram handle"
        />

        <FormTextField
          error={errors.phone}
          helperText="Your phone number"
          variant="outlined"
          className="w-80 mb-2"
          errorText="Invalid phone number"
          inputProps={register("phone", {
            required: true,
            pattern: phoneRegex,
          })}
        />
        <LoadingButton
          variant="outlined"
          color="primary"
          className="uppercase"
          loading={isSavingBrand}
          type="submit"
        >
          <SaveIcon /> SAVE CHANGES
        </LoadingButton>
      </form>
    </div>
  );
};

export default Account;
