import Auth from "@aws-amplify/auth";
import { Typography } from "@material-ui/core";
import Campaign from "@socialplug/common/interfaces/campaign";
import Commit from "@socialplug/common/interfaces/commit";
import Offer from "@socialplug/common/interfaces/offer";
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";
import OfferCard from "../OfferCard";

const SubmissionNegotiation: React.FC<any> = (props) => {
  const commit: Commit = props.commit;
  const campaign: Campaign = props.campaign;
  const user: User = props.user;
  const [offersFromBrand, setOffersFromBrand] = useState<Offer[]>([]);
  const [offersToBrand, setOffersToBrand] = useState<Offer[]>([]);

  const [, getOffers] = useApi("GET", "offer", "list");

  useEffect(() => {
    (async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const foundOffersToBrand: Offer[] = await getOffers({
          queryParams: {
            toId: user.username,
            commitId: commit.id,
            pending: true,
          },
        });
        setOffersToBrand(foundOffersToBrand);
        const foundOffersFromBrand: Offer[] = await getOffers({
          queryParams: {
            byId: user.username,
            pending: true,
          },
        });
        setOffersFromBrand(foundOffersFromBrand);
      } catch {}
    })();
  }, [commit, getOffers, user]);

  return (
    <React.Fragment>
      {offersFromBrand?.length > 0 && (
        <Typography className="mt-4 mx-4 text-center">
          Your offer of £{offersFromBrand[0].amount} has been sent to{" "}
          {user?.name} and is awaiting a response.
        </Typography>
      )}
      {offersToBrand?.length > 0 && (
        <OfferCard user={user} campaign={campaign} offer={offersToBrand[0]} />
      )}
    </React.Fragment>
  );
};

export default SubmissionNegotiation;
