import React from "react";
import OnboardingCarousel from "../../components/OnboardingCarousel";

const RegisterBrand = () => {
  return (
    <React.Fragment>
      <OnboardingCarousel />
    </React.Fragment>
  );
};

export default RegisterBrand;
