import { Typography } from "@material-ui/core";
import BrandTable from "../../components/admin/BrandTable";

const AdminBrands: React.FC = () => {
  return (
    <div className="flex flex-col place-content-center place-items-center">
      <Typography className="mt-4 text-xl text-center font-bold">
        Brands
      </Typography>
      <BrandTable />
    </div>
  );
};

export default AdminBrands;
