import { Avatar, Paper, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Commit from "@socialplug/common/interfaces/commit";
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import Divider from "@uiw/react-divider";
import React, {useContext, useEffect, useState} from "react";
import { useParams } from "react-router";
import Offer from "../../../components/commit/Offer";
import InitialOffer from "../../../components/negotiation/InitialOffer";
import SubmissionNegotiation from "../../../components/negotiation/SubmissionNegotiation";
import InfluencerInformation from "../InfluencerInformation";
import CampaignContext from "../../../context/CampaignContext";

type SubmissionPageParams = {
  id: string;
  commitId: string;
};

const Submission = () => {
  const { id, commitId } = useParams<SubmissionPageParams>();
  const [commit, setCommit] = useState<Commit>();
  const { campaign, allCommits } = useContext(CampaignContext);

  const [, getUser] = useApi("GET", "creator", "fetch");
  const [user, setUser] = useState<User>();
  const [commitImageIndex, setCommitImageIndex] = useState(0);

  useEffect(() => {
    (async () => {
      const commitsById: Commit[] = allCommits ? allCommits.filter(c => c.id === commitId) : []

      const foundCommit: Commit | undefined = commitsById && commitsById.length > 0 ? commitsById[0] : undefined
      setCommit(foundCommit);
      const foundUser: User = await getUser({
        queryParams: {userId: foundCommit?.userId},
      });
      setUser(foundUser);

    })()
  }, [allCommits]);

  const onCommitImageNextClick = () => {
    if (commit?.images != null) {
      if (commitImageIndex < commit?.images.length - 1) {
        setCommitImageIndex(commitImageIndex + 1);
      }
    }
  };

  const onCommitImageBackClick = () => {
    if (commitImageIndex > 0) {
      setCommitImageIndex(commitImageIndex - 1);
    }
  };

  return (
    <div className="flex flex-col max-w-screen place-content-center place-items-center mt-5">
      <Paper className="w-11/12 lg:w-7/12">
        <div className="flex flex-col lg:flex-row ">
          {commit?.images != null && (
            <div className=" my-2 flex flex-row place-content-center place-items-center">
              <ArrowBackIosIcon
                onClick={() => onCommitImageBackClick()}
                className={
                  commitImageIndex === 0 ? "fill-current text-white" : ""
                }
              />
              <img
                className="lg:w-10/12 max-w-xs"
                alt="submission"
                src={
                  commit?.images && commit?.images?.length > commitImageIndex
                    ? commit?.images[commitImageIndex].url
                    : "noimaage"
                }
              />
              <ArrowForwardIosIcon
                onClick={() => onCommitImageNextClick()}
                className={
                  commitImageIndex === commit.images.length - 1
                    ? "fill-current text-white"
                    : ""
                }
              />{" "}
            </div>
          )}
          {commit?.postType === "Video" &&
            commit?.videos &&
            commit?.videos !== null &&
            commit?.videos.length > 0 && (
              <div className=" my-2 flex flex-row place-content-center place-items-center">
                <video controls>
                  <source src={commit.videos[0].url} type="video/mp4" />
                </video>
              </div>
            )}
          <div className="ml-2 place-content-start place-items-start lg:w-1/2">
            <div className="flex flex-row mt-2">
              <Avatar src={user?.profileImage?.url} className="mt-1" />
              <div className="flex flex-col ml-2 place-content-center">
                <Typography>{user?.name}</Typography>
              </div>
            </div>
            <Divider />
            <Typography>{commit?.postDescription}</Typography>
            <Divider />
            {commit?.commentsToBrand !== '' && (
              <React.Fragment>
                <Typography>Comments to you: </Typography>
                <Typography>{commit?.commentsToBrand}</Typography>
                <Divider />
              </React.Fragment>
            )}
          </div>
        </div>
      </Paper>

      {commit?.state === "AwaitingSubmissionApproval" && (
        <Paper className="my-5 ml-2">
          <div className="mx-2 pb-5">
            <Typography className="font-bold">
              Would you like to approve this content?
            </Typography>
            <Offer commit={commit} />
          </div>
        </Paper>
      )}

      {commit?.state === "SubmissionApproved" && (
        <Paper className="my-5 pb-5">
          <InitialOffer user={user} commit={commit} campaign={campaign} />
        </Paper>
      )}

      {commit?.state === "InNegotiation" && (
        <Paper className="my-5 pb-5 mx-4">
          <SubmissionNegotiation
            user={user}
            commit={commit}
            campaign={campaign}
          />
        </Paper>
      )}

      <Paper className="mt-5 mx-4 w-11/12 lg:w-8/12 place-items-center place-content-center flex flex-col">
        <Typography className="font-bold text-xl">Creator Profile</Typography>
        <InfluencerInformation userId={commit?.userId} />
      </Paper>
    </div>
  );
};

export default Submission;
