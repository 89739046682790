import { Button, MenuItem, Paper, Typography } from "@material-ui/core";
import { AddAPhoto } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import Campaign from "@socialplug/common/interfaces/campaign";
import { useApi } from "@socialplug/common/utilities/api";
import { addWeeks, addYears } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useAlert } from "../../../components/alerts/AlertServiceContext";
import AddressTextField, {
  AddressType,
} from "../../../components/forms/AddressTextField";
import FormSelectField from "../../../components/forms/FormSelect";
import FormTextField from "../../../components/forms/FormTextField";
import ImageSelectionGrid from "../../../components/forms/ImageSelectionGrid";
import ImageUploadField from "../../../components/forms/ImageUploadField";
import MultipleTagTextField from "../../../components/forms/MultipleTagTextField";
import StringCheckList from "../../../components/forms/StringCheckList";
import LoadingButton from "../../../components/LoadingButton";
import YesNoDialog from "../../../components/YesNoDialog";
import BrandContext from "../../../context/BrandContext";

const instaHandleReg =
  /(?:@)([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/;

const campaignObjectives = [
  "I'm launching a new product or service",
  "I'm promoting/activating an event",
  "I'm driving awareness about my product or service",
  "I'm sourcing content",
  "I'm amplifying a campaign running elsewhere",
  "I'm trialing influencer marketing",
  "Other",
];

const campaignCategories = [
  "Animals",
  "Automative",
  "Beauty & Personal Care",
  "Business, Finance & Insurance",
  "Children & Family",
  "Education & Books",
  "Entertainment & Events",
  "Fashion",
  "Food & Drink",
  "Health, Fitness & Sport",
  "Home & Garden",
  "Photography, Art & Design",
  "Restaurants, Bars & Hotels",
  "Social Enterprise & Not-For-Profit",
  "Social Media, Web & Tech",
  "Travel & Destinations",
];

type CampaignFormPageParams = {
  id?: string;
  type?: "Online" | "Physical";
};

const CampaignFormPage = () => {
  const { type, id } = useParams<CampaignFormPageParams>();
  const history = useHistory();
  const [createButtonDisabled, setCreateButtonDisabled] = useState(true);
  const [cancelCampaignClicked, setCancelCampaignClicked] = useState(false);

  const [isCreatingCampaign, callCreateCampaign, createCampaignErr] = useApi(
    "POST",
    "campaign",
    "create"
  );

  const [isSavingCampaign, callSaveCampaign, saveCampaignErr] = useApi(
    "POST",
    "campaign",
    "edit"
  );

  const [, callGetCampaign] = useApi("GET", "campaign", "fetch");
  const [, listCampaigns] = useApi("GET", "campaign", "list");
  const brandContext = useContext(BrandContext);
  const [campaign, setCampaign] = useState<Campaign>();

  const alert = useAlert();

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<Campaign>({
    defaultValues: {
      type,
      rewardType: "Money",
      moodboard: [],
      objectives: [],
      hashtags: [],
      rules: [],
      captionRules: [],
      restrictions: [],
      lengthWeeks: 4,
      startDate: new Date(),
      category: "Animals",
      url: "",
    },
  });

  useEffect(() => {
    (async () => {
      try {
        if (id && id != null) {
          const campaign: Campaign = await callGetCampaign({
            queryParams: { campaignId: id },
          });
          if (campaign && campaign != null) {
            if (!campaign.captionRules) {
              campaign.captionRules = [];
            }
            setCampaign(campaign);
            setValue("moodboard", campaign.moodboard);
          }
          if (id) reset(campaign);
        }
      } catch {}
    })();
  }, [id, reset, callGetCampaign]);

  useEffect(() => {
    if (saveCampaignErr.current) {
      alert({
        variant: "info",
        title: "Couldn't Update Campaign",
        description:
          "We couldn't update the campaign for some reason. Contact support.",
      });
      saveCampaignErr.current = undefined;
    }

    if (createCampaignErr.current) {
      alert({
        variant: "info",
        title: "Couldn't Create Campaign",
        description:
          "We couldn't create the campaign for some reason. Contact support.",
      });

      createCampaignErr.current = undefined;
    }
  }, [saveCampaignErr, createCampaignErr, alert]);

  const rewardTypeValue = watch("rewardType");
  const objectives = watch("objectives");
  const rules = watch("rules");
  const captionRules = watch("captionRules");
  //const restrictions = watch("restrictions");
  const hashtags = watch("hashtags");
  const coverImage = watch("coverImage");
  const moodboard = watch("moodboard");
  const campaignLength = watch("lengthWeeks");
  const startDate = watch("startDate");
  const category = watch("category");

  const onSubmit = async (data: Partial<Campaign>) => {
    const weeks = data.lengthWeeks!;

    if (!campaign || campaign == null) {
      const endDate =
        weeks > 0
          ? addWeeks(data.startDate!, weeks)
          : addYears(data.startDate!, 1000);
      data.endDate = endDate;
    } else {
      const endDate =
        weeks > 0
          ? addWeeks(new Date(data.startDate!), weeks)
          : addYears(new Date(data.startDate!), 1000);
      data.endDate = endDate;
    }

    if (moodboard.length === 0 && campaign && campaign != null) {
      data.moodboard = campaign.moodboard;
    }

    try {
      if (id) {
        await callSaveCampaign({ body: { campaignId: id, changes: data } });
        if (!brandContext.activeBrand?.hasCreatedCampaign) {
        }
        await alert({
          variant: "info",
          title: "Woohoo!",
          description: "You've updated your campaign!",
        });
        history.push(`/campaign/${id}/details`);
      } else {
        const newCampaign: Campaign = await callCreateCampaign({
          body: data,
          queryParams: { brandId: brandContext.activeBrand?.id },
        });
        await alert({
          variant: "info",
          title: "Woohoo!",
          description: "You've created a campaign!",
        });

        //update brand context campaign list
        const foundCampaigns = await listCampaigns({
          queryParams: { brandId: brandContext.activeBrand?.id },
        });
        brandContext.setBrandCampaigns(foundCampaigns);
        history.push(`/campaign/${newCampaign.id}/details`);
      }
    } catch (e) {}
  };

  const campaignObjectiveSelected = (value: string) => {
    const newObjectives = [...objectives];
    const index = newObjectives.indexOf(value);
    if (index !== -1) {
      newObjectives.splice(index, 1);
    } else {
      newObjectives.push(value);
    }
    setValue("objectives", newObjectives);
  };

  const addressValueChanged = (newAddressValue: AddressType) => {
    setValue("addressCoordinates", newAddressValue.addressCoordinates as any);
    setValue("addressFormatted", newAddressValue.addressFormatted);
    setValue("addressPlaceId", newAddressValue.addressPlaceId);
  };

  useEffect(() => {
    const newMoodboard = watch("moodboard");
    if (campaign && campaign != null) {
      setCreateButtonDisabled(false);
    } else if (!coverImage || newMoodboard.length === 0) {
      setCreateButtonDisabled(true);
    } else {
      return setCreateButtonDisabled(false);
    }
  }, [watch, moodboard, coverImage, campaign]);

  const isOnline = type === "Online" || getValues("type") === "Online";

  const cancelCampaign = async () => {
    const date = new Date();
    const yesterday = new Date(date);
    yesterday.setDate(yesterday.getDate() - 1);

    if (campaign != null) {
      const edittedCampaign: Partial<Campaign> = campaign;
      edittedCampaign.endDate = yesterday;
      await callSaveCampaign({
        body: {
          campaignId: campaign?.id,
          changes: {
            title: edittedCampaign.title,
            type: edittedCampaign.type,
            category: edittedCampaign.category,
            url: edittedCampaign.url,
            callToAction: edittedCampaign.callToAction,
            description: edittedCampaign.description,
            moodboard: edittedCampaign.moodboard,
            rewardType: edittedCampaign.rewardType,
            startDate: edittedCampaign.startDate,
            endDate: edittedCampaign.endDate,
            atHandle: edittedCampaign.atHandle,
            hashtags: edittedCampaign.hashtags,
            coverImage: edittedCampaign.coverImage,
            rules: edittedCampaign.rules,
            captionRules: edittedCampaign.captionRules,
          },
        },
      });
      history.push("/dashboard/home");
    }
  };

  if (id && !getValues("id")) {
    return <div>Loading</div>;
  }

  return (
    <div className="flex flex-col place-items-center place-content-center">
      <Paper
        variant="outlined"
        className="py-0 px-4 border-0 w-11/12 flex flex-col items-center my-10 lg:border lg:py-10 lg:px-0"
      >
        <form
          className="flex flex-col items-center max-w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="text-center">
            <Typography variant="h4">Campaign Details</Typography>
            <Typography variant="subtitle2">
              We just need to know a little bit about the campaign
            </Typography>
          </div>

          <ImageUploadField
            purpose="CampaignCover"
            id="select-cover"
            onImageUploaded={(image) => {
              setValue("coverImage", image);
            }}
          >
            <div
              className="flex flex-col bg-white w-full border-2 mb-2 place-items-center place-content-center mt-8 h-64 rounded bg-cover bg-no-repeat bg-center"
              style={{
                backgroundImage: `url(${coverImage?.url})`,
              }}
            >
              {!coverImage && (
                <React.Fragment>
                  <AddAPhoto className="text-grey" />

                  <Typography className="text-center">
                    Feature your product in a way that will quickly grab the
                    attention of our creators
                  </Typography>
                  <Typography className="text-center">
                    We recommend an image atleast 500x500 in size
                  </Typography>
                </React.Fragment>
              )}
            </div>
          </ImageUploadField>

          <FormTextField
            error={errors.title}
            inputProps={register("title", { required: true })}
            helperText="A short and snappy title for your campaign"
            errorText="This field is required"
            label="Campaign Title"
          />

          <FormTextField
            error={errors.description}
            inputProps={register("description", { required: true })}
            helperText="Tell creators about your brand"
            errorText="This field is required"
            label="Tell creators about your brand"
          />

          <FormTextField
            error={errors.callToAction}
            inputProps={register("callToAction", { required: true })}
            helperText="Eg. We're looking to taget 18-30 yr old females in Manchester"
            errorText="This field is required"
            label="Your target audience"
          />

          <FormTextField
            error={errors.url}
            helperText="www.mybrand.com/product"
            errorText="This must be a valid URL"
            label="Where can people purchase this offer?"
            inputProps={register("url", {
              required: true,
            })}
          />

          <FormTextField
            error={errors.atHandle}
            inputProps={register("atHandle", {
              required: true,
              pattern: instaHandleReg,
            })}
            helperText="Your brand's instagram handle"
            errorText="This field is required and must include the @ symbol"
            label="@mybrand"
          />

          <MultipleTagTextField
            placeholder="#trendingtopic"
            error={errors.hashtags}
            defaultValue={hashtags}
            onChange={(hashtags: string[]) => setValue("hashtags", hashtags)}
            helperText="Add the hashtags you would like creators in include in their content"
            label="Hashtags"
            errorText="This field is required"
            required
          />

          <FormSelectField
            error={errors.rewardType}
            inputProps={register("rewardType", { required: true })}
            helperText="How will you reward creators for posting content?"
            errorText="Please select a Reward type"
            label="Reward Type"
            value={rewardTypeValue}
          >
            <MenuItem value="Discount">Discount</MenuItem>
            <MenuItem value="Money">Money</MenuItem>
            <MenuItem value="Gift">Gift</MenuItem>
          </FormSelectField>

          {rewardTypeValue === "Money" && (
            <FormTextField
              error={errors.budget}
              helperText="Please enter the budget for this campaign"
              errorText=""
              label="Budget"
              type="number"
              required={rewardTypeValue === "Money"}
              inputProps={register("budget")}
            />
          )}

          <FormTextField
            error={errors.discountCode}
            helperText="Optional: Enable your approved influencers to redeem your offer with this exclusive website code"
            errorText=""
            label="Exclusive website code"
            inputProps={register("discountCode")}
          />

          <FormTextField
            error={errors.rewardDescription}
            helperText="Eg. $30-$50, 20% off, Free meal"
            errorText="This is a required field"
            label="Description of the reward"
            inputProps={register("rewardDescription", {
              required: true,
            })}
          />

          <FormSelectField
            error={errors.category}
            inputProps={register("category", { required: true })}
            helperText="What category does this campaign fall in to?"
            errorText="Please select a category"
            label="Category"
            value={category}
          >
            {campaignCategories.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </FormSelectField>

          {!isOnline && (
            <AddressTextField
              variant="outlined"
              onAddressChanged={addressValueChanged}
              defaultPlaceId={getValues("addressPlaceId")}
              error={errors.title}
              helperText="Where will the people flock to?"
              errorText="This field is required"
              label="Your Brand Address"
            />
          )}

          <FormTextField
            error={errors.maximumRedemptions}
            inputProps={register("maximumRedemptions", {
              valueAsNumber: true,
              required: true,
              validate: (n) => !isNaN(n) && n > 0,
            })}
            type="number"
            helperText="How many infuencers can redeem this offer?"
            errorText="This field must be number > 0"
            label="Maximum Redemptions"
          />

          <div className="mt-12 text-center max-w-full">
            <Typography variant="h4">Campaign Times</Typography>
            <Typography variant="subtitle2">
              A week, a month or as long as you like - we'll keep this campaign
              going
            </Typography>

            <div className="w-96 mt-8 mx-auto max-w-full">
              <KeyboardDatePicker
                className="w-full"
                value={startDate}
                InputAdornmentProps={{ position: "start" }}
                onChange={(newDate: MaterialUiPickersDate) => {
                  setValue("startDate", newDate as Date);
                }}
                inputVariant="outlined"
                disableToolbar
                label="When is your campaign going live?"
                format="MM/dd/yyyy"
              />
            </div>

            <FormSelectField
              error={errors.lengthWeeks}
              inputProps={register("lengthWeeks", {
                required: true,
                valueAsNumber: true,
              })}
              helperText="How long will this campaign run?"
              errorText="Please select a length of time"
              label="Campaign Length"
              value={campaignLength}
            >
              <MenuItem value={1}>1 Week</MenuItem>
              <MenuItem value={2}>2 Weeks</MenuItem>
              <MenuItem value={3}>3 Weeks</MenuItem>
              <MenuItem value={4}>4 Weeks</MenuItem>
              <MenuItem value={0}>Ongoing</MenuItem>
            </FormSelectField>
          </div>

          <div className="mt-12 text-center max-w-full">
            <Typography variant="h4">Visual Direction</Typography>
            <Typography variant="subtitle2">
              This will help creators to understand the type of content you are
              looking for
            </Typography>
            <Typography variant="subtitle2">
              We recommend an images atleast 500x500 in size
            </Typography>
            <ImageSelectionGrid
              className="mt-8"
              name="moodboard"
              control={control}
            />
          </div>

          <div className="mt-12 text-center flex flex-col place-content-center place-items-center max-w-full">
            <Typography variant="h4">
              What is your campaign objective?
            </Typography>
            <Typography variant="subtitle2">
              We want to know how we can help
            </Typography>

            <StringCheckList
              className="mt-4 "
              values={campaignObjectives}
              selectedValues={objectives}
              onSelectValue={campaignObjectiveSelected}
            />
          </div>

          <div className="flex flex-col mt-12 text-center max-w-full place-items-center place-content-center">
            <Typography className=" text-center" variant="h4">
              Content Direction
            </Typography>
            <Typography className="text-center" variant="subtitle2">
              Describe the type of content you are looking for plus any specific
              direction for the instagram caption
            </Typography>
            <MultipleTagTextField
              placeholder="Eg. Upload a carousel of your workout routine"
              error={errors.rules}
              defaultValue={rules}
              onChange={(rules: string[]) => setValue("rules", rules)}
              helperText="What type of image content is encouraged?"
              label="What type of image content is encouraged?"
              errorText="This field is required"
              required
            />

            <MultipleTagTextField
              placeholder="Eg. Running a competition asking your followers to..."
              error={errors.captionRules}
              defaultValue={captionRules}
              onChange={(captionRules: string[]) =>
                setValue("captionRules", captionRules)
              }
              helperText="Any specific direction for the instagram caption?"
              label="Any specific direction for the instagram caption?"
              errorText="This field is required"
              required
            />
          </div>

          <LoadingButton
            variant="outlined"
            color="primary"
            className="mt-8 mb-2 w-40 rounded-xl"
            type="submit"
            disabled={createButtonDisabled}
            loading={isCreatingCampaign || isSavingCampaign}
          >
            {id ? "Save Changes" : "Create"}
          </LoadingButton>

          {campaign != null && (
            <Button
              onClick={() => setCancelCampaignClicked(true)}
              variant="outlined"
              color="primary"
              className="mt-8 mb-2 w-40 rounded-xl"
            >
              Cancel Campaign
            </Button>
          )}
        </form>
      </Paper>

      <YesNoDialog
        open={cancelCampaignClicked}
        title="Cancel campaign?"
        text="Are you sure you wish to cancel this campaign?"
        yesOnClick={() => cancelCampaign()}
        noOnClick={() => setCancelCampaignClicked(false)}
      />
    </div>
  );
};

export default CampaignFormPage;
