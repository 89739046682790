import { Auth } from "@aws-amplify/auth";
import {
  Avatar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BrightnessAutoIcon from "@material-ui/icons/BrightnessAuto";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import HomeIcon from "@material-ui/icons/Home";
import ListIcon from "@material-ui/icons/List";
import MenuIcon from "@material-ui/icons/Menu";
import PersonIcon from "@material-ui/icons/Person";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import SettingsIcon from "@material-ui/icons/Settings";
import TimelineIcon from "@material-ui/icons/Timeline";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import YesNoDialog from "./YesNoDialog";

type ResponseDrawerProps = {
  brandName?: string;
  admin: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const ResponsiveDrawer: React.FC<ResponseDrawerProps> = ({
  brandName,
  admin,
  ...props
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [theActiveTab, setTheActiveTab] = useState<string>("All");
  const [signOutDialogOpen, setSignOutDialogOpen] = useState(false);

  let history = useHistory();

  useEffect(() => {
    const pathname = window.location.pathname;
    switch (pathname) {
      case "/dashboard/home":
        setTheActiveTab("home");
        break;
      case "/dashboard/campaigns/all":
        setTheActiveTab("All");
        break;
      case "/dashboard/campaigns/active":
        setTheActiveTab("Active");
        break;
      case "/dashboard/campaigns/expired":
        setTheActiveTab("Expired");
        break;
      case "/dashboard/campaign/create":
      case "/dashboard/campaign/create/online":
      case "/dashboard/campaign/create/physical":
        setTheActiveTab("Create Campaign");
        break;
      case "/dashboard/profile":
      case "/dashboard/profile/edit":
        setTheActiveTab("Profile");
        break;
      case "/dashboard/notifications":
        setTheActiveTab("Notifications");
        break;
      case "/dashboard/settings":
        setTheActiveTab("Settings");
        break;
      case "/dashboard/your%20subscription":
        setTheActiveTab("Your Subscription");
        break;
      case "/dashboard/admin/stats":
        setTheActiveTab("stats");
        break;
      case "/dashboard/admin/influencers":
        setTheActiveTab("influencers");
        break;
      case "/dashboard/admin/brands":
        setTheActiveTab("brands");
        break;
      case "/dashboard/admin/campaigns":
        setTheActiveTab("All Campaigns");
        break;
      case "/dashboard/admin/allInfluencers":
        setTheActiveTab("All Influencers");
        break;
      case "/dashboard/admin/tochase":
        setTheActiveTab("To Chase");
        break;
      default:
        setTheActiveTab("");
        break;
    }
  }, []);

  const isActive = (item: string) => {
    return theActiveTab === item;
  };

  const classes = (item: string) => {
    return isActive(item)
      ? "bg-yellow-300 bg-opacity-50"
      : "lg:hover:bg-yellow-300 lg:hover:bg-opacity-50";
  };

  const adminClasses = (item: string) => {
    return isActive(item)
      ? "bg-red-300 bg-opacity-50"
      : "lg:hover-red-300 lg:hover:bg-opacity-50";
  };

  const redirectToAdmin = (state: string) => {
    switch (state) {
      case "campaigns":
        setTheActiveTab("All Campaigns");
        break;
      case "toChase":
        setTheActiveTab("To Chase");
        break;
      default:
        setTheActiveTab(state);
        break;
    }
    history.push(`/dashboard/admin/${state.toLowerCase()}`);
    setDrawerOpen(false);
  };

  const redirectToCampaign = (state: string) => {
    setTheActiveTab(state);
    history.push(`/dashboard/campaigns/${state.toLowerCase()}`);
    setDrawerOpen(false);
  };

  const redirect = async (navItem: string) => {
    if (navItem.toLowerCase() === "sign out") {
      setSignOutDialogOpen(true);
    } else {
      setTheActiveTab(navItem);
      history.push(`/dashboard/${navItem.toLowerCase()}`);
      setDrawerOpen(false);
    }
  };

  const signOut = async () => {
    await Auth.signOut();
    window.location.href = "/";
  };

  const redirectCreateCampaign = () => {
    setTheActiveTab("Create Campaign");
    history.push(`/dashboard/campaign/create`);
    setDrawerOpen(false);
  };

  const drawerContent = (
    <React.Fragment>
      <div className="grid grid-cols-2 grid-flow-col">
        <div className="flex place-items-center place-content-center mt-5 mb-5 ml-3">
          <Avatar className="mr-2" src={"/logo.svg"} />
          <Typography>{brandName}</Typography>
        </div>
      </div>

      <List className="w-4/5">
        <ListItem
          className={`${classes("home")} rounded-r-full`}
          onClick={() => redirect("home")}
        >
          <HomeIcon />
          <ListItemText className="ml-2">Dashboard</ListItemText>
        </ListItem>

        <h2 className="ml-2 mt-2 mb-2">
          <strong>YOUR CAMPAIGNS</strong>
        </h2>

        <ListItem
          className={`${classes("Active")} rounded-r-full`}
          onClick={() => redirectToCampaign("Active")}
        >
          <FlashOnIcon />
          <ListItemText className="ml-2">Active</ListItemText>
        </ListItem>

        <ListItem
          className={`${classes("Expired")} rounded-r-full`}
          onClick={() => redirectToCampaign("Expired")}
        >
          <WatchLaterIcon />
          <ListItemText className="ml-2">Expired</ListItemText>
        </ListItem>

        <ListItem
          className={`${classes("All")} rounded-r-full`}
          onClick={() => redirectToCampaign("All")}
        >
          <ListIcon />
          <ListItemText className="ml-2">All</ListItemText>
        </ListItem>

        <ListItem
          className={`${classes("Create Campaign")} rounded-r-full`}
          onClick={() => redirectCreateCampaign()}
        >
          <AddCircleOutlineIcon />
          <ListItemText className="ml-2">Create Campaign</ListItemText>
        </ListItem>
      </List>

      <h2 className="ml-2 mt-2 mb-2 font-bold">ACCOUNT</h2>
      <List className=" w-4/5">
        <ListItem
          className={`${classes("Profile")} rounded-r-full`}
          onClick={() => redirect("Profile")}
        >
          <PersonIcon />
          <ListItemText className="ml-2">Profile</ListItemText>
        </ListItem>

        <ListItem
          className={`${classes("Content")} rounded-r-full`}
          onClick={() => redirect("content")}
        >
          <PhotoLibraryIcon />
          <ListItemText className="ml-2">Content</ListItemText>
        </ListItem>

        <ListItem
          className={`${classes("Settings")} rounded-r-full`}
          onClick={() => redirect("Settings")}
        >
          <SettingsIcon />
          <ListItemText className="ml-2">Settings</ListItemText>
        </ListItem>

        <ListItem
          className={`${classes("Sign out")} rounded-r-full`}
          onClick={() => redirect("Sign out")}
        >
          <ArrowBackIcon />
          <ListItemText className="ml-2">Sign out</ListItemText>
        </ListItem>
      </List>
      {admin && (
        <>
          <h2 className="ml-2 mt-2 mb-2 font-bold">ADMIN</h2>
          <List className="w-4/5">
            <ListItem
              className={`${adminClasses("stats")} rounded-r-full`}
              onClick={() => redirectToAdmin("stats")}
            >
              <BrightnessAutoIcon />
              <ListItemText className="ml-2">Platform Statistics</ListItemText>
            </ListItem>
            <ListItem
              className={`${adminClasses("influencers")} rounded-r-full`}
              onClick={() => redirectToAdmin("influencers")}
            >
              <BrightnessAutoIcon />
              <ListItemText className="ml-2">Waiting Influencers</ListItemText>
            </ListItem>
            <ListItem
              className={`${adminClasses("allInfluencers")} rounded-r-full`}
              onClick={() => redirectToAdmin("allInfluencers")}
            >
              <BrightnessAutoIcon />
              <ListItemText className="ml-2">All Influencers</ListItemText>
            </ListItem>
            <ListItem
              className={`${adminClasses("brands")} rounded-r-full`}
              onClick={() => redirectToAdmin("brands")}
            >
              <BrightnessAutoIcon />
              <ListItemText className="ml-2">Brands</ListItemText>
            </ListItem>
            <ListItem
              className={`${adminClasses("All Campaigns")} rounded-r-full`}
              onClick={() => redirectToAdmin("campaigns")}
            >
              <BrightnessAutoIcon />
              <ListItemText className="ml-2">Campaigns</ListItemText>
            </ListItem>
            <ListItem
              className={`${adminClasses("To Chase")} rounded-r-full`}
              onClick={() => redirectToAdmin("toChase")}
            >
              <BrightnessAutoIcon />
              <ListItemText className="ml-2">To Chase</ListItemText>
            </ListItem>
          </List>
        </>
      )}
    </React.Fragment>
  );

  return (
    <div className="w-screen">
      <Toolbar className="lg:hidden lg:bg-transparent bg-blue-400 text-white max-w-screen">
        <IconButton onClick={() => setDrawerOpen(true)}>
          <MenuIcon className="fill-current text-white" />
        </IconButton>
        <h1 className="text-xl ml-2 lg:hidden">{theActiveTab}</h1>
      </Toolbar>

      <div className="hidden lg:block">
        <Drawer
          anchor="left"
          open={drawerOpen}
          variant="temporary"
          ModalProps={{
            keepMounted: true,
          }}
          onBackdropClick={() => setDrawerOpen(false)}
        >
          {drawerContent}
        </Drawer>
        <Divider />
      </div>

      <Drawer
        className="hidden lg:block"
        variant="permanent"
        open
        classes={{ paper: "w-72" }}
      >
        {drawerContent}
      </Drawer>

      <YesNoDialog
        open={signOutDialogOpen}
        title="Sign Out?"
        text="Are you sure you wish to sign out?"
        yesOnClick={() => signOut()}
        noOnClick={() => setSignOutDialogOpen(false)}
      />
    </div>
  );
};

export default ResponsiveDrawer;
