import { Typography } from "@material-ui/core";
import Brand from "@socialplug/common/interfaces/brand";
import React, { useContext, useEffect } from "react";
import { useApi } from "../../../../shared/utilities/api";
import BrandContext from "../../context/BrandContext";

const PaymentHolding: React.FC = () => {
  const [, fetchBrands] = useApi("GET", "brand", "list");
  const brandContext = useContext(BrandContext);

  useEffect(() => {
    (async () => {
      try {
        const timer = setInterval(async () => {
          const foundBrands: Brand[] = await fetchBrands();
          if (foundBrands != null && foundBrands.length > 0) {
            brandContext.setActiveBrand(foundBrands[0]);
            brandContext.setBrands(foundBrands);
          }
        }, 15000);
        return () => clearInterval(timer);
      } catch {}
    })();
  }, [brandContext, fetchBrands]);

  useEffect(() => {
    if (brandContext.activeBrand?.subscriptionStatus === "Active") {
      window.location.href = "/dashboard/home";
    }
  }, [brandContext]);

  return (
    <div className="flex flex-col place-content-center place-items-center">
      <img
        src="https://i.imgur.com/7RjTtkG.png"
        alt="waiting"
        className="lg:w-2/5"
      />
      <Typography className="text-4xl font-bold text-center">
        Your subscription is currently being processed. Please wait.
      </Typography>
    </div>
  );
};

export default PaymentHolding;
