import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";
import CompletedAssignedCampaignsRow from "./CompletedAssignedCampaignsRow";

const CompletedAssignedCampaignsTable: React.FC<any> = (props) => {
  const [users, setUsers] = useState<User[]>([]);
  const [, getUsers] = useApi("GET", "admin", "listCreatorsCompletedAssigned");

  useEffect(() => {
    try {
      (async () => {
        const foundUsers: User[] = await getUsers();
        setUsers(foundUsers);
      })();
    } catch {}
  }, [getUsers]);

  return (
    <React.Fragment>
      {users?.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((user) => (
                <CompletedAssignedCampaignsRow user={user} key={user.id} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {(users?.length === 0 || users == null) && (
        <Typography>No pending completed assigned campaigns</Typography>
      )}
    </React.Fragment>
  );
};

export default CompletedAssignedCampaignsTable;
