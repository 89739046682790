import {
  Avatar,
  Button,
  Card,
  Modal,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import User from "@socialplug/common/interfaces/user";
import { useState } from "react";
import { useHistory } from "react-router";
import { useApi } from "../../../../shared/utilities/api";
import AssignCampaignModal from "./AssignCampaignModal";

const CreatorTableRow: React.FC<any> = (props) => {
  const user: User = props.user;
  const [assignCampaignModalOpen, setAssignCampaignModalOpen] = useState(false);
  const [rejectedModalOpen, setRejectedModalOpen] = useState(false);
  const [acceptModalOpen, setAcceptModalOpen] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [approved, setApproved] = useState(false);
  let history = useHistory();
  const [, assignCreatorStatus] = useApi(
    "POST",
    "admin",
    "assignCreatorStatus"
  );

  const rejectInfluencer = async () => {
    await assignCreatorStatus({
      body: {
        creatorId: user.id,
        status: "Rejected",
      },
    });
    setRejected(true);
    setRejectedModalOpen(false);
  };

  const acceptInfluencer = async () => {
    await assignCreatorStatus({
      body: {
        creatorId: user.id,
        status: "Approved",
      },
    });
    setApproved(true);
    setAcceptModalOpen(false);
  };

  return (
    <TableRow>
      <TableCell align="center">
        <div className="flex flex-col place-content-center place-items-center">
          <Avatar src={user?.profileImage?.url} />
          <Typography>{user.name}</Typography>
        </div>
      </TableCell>
      <TableCell align="center">
        <Typography>{user.email}</Typography>
      </TableCell>
      <TableCell align="center">
        <Button
          variant="outlined"
          onClick={() => history.push(`/admin/influencer/${user?.id}`)}
        >
          View Profile
        </Button>
      </TableCell>
      <TableCell align="center">
        <Button
          variant="outlined"
          onClick={() => setAssignCampaignModalOpen(true)}
        >
          Assign Campaign
        </Button>
      </TableCell>
      <TableCell align="center">
        <Button
          variant="outlined"
          disabled={approved || rejected}
          onClick={() => setAcceptModalOpen(true)}
        >
          Approve Creator
        </Button>
      </TableCell>
      <TableCell align="center">
        <Button
          variant="outlined"
          disabled={rejected || approved}
          onClick={() => setRejectedModalOpen(true)}
          className="text-red-500"
        >
          Reject Creator
        </Button>
      </TableCell>

      <Modal
        open={rejectedModalOpen}
        className="overflow-scroll flex flex-col place-content-center place-items-center "
        onClose={() => setRejectedModalOpen(false)}
        onBackdropClick={() => setRejectedModalOpen(false)}
      >
        <Card className="flex flex-col">
          <div className="m-4">
            <Typography>Reject {user.name}?</Typography>
            <Button
              variant="outlined"
              className="text-red-500 mr-4 "
              onClick={() => rejectInfluencer()}
            >
              Reject
            </Button>
            <Button
              variant="outlined"
              onClick={() => setRejectedModalOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </Card>
      </Modal>

      <Modal
        open={acceptModalOpen}
        className="overflow-scroll flex flex-col place-content-center place-items-center "
        onClose={() => setAcceptModalOpen(false)}
        onBackdropClick={() => setAcceptModalOpen(false)}
      >
        <Card className="flex flex-col">
          <div className="m-4">
            <Typography>Approve {user.name}?</Typography>
            <Button
              variant="outlined"
              className="text-red-500 mr-4 "
              onClick={() => acceptInfluencer()}
            >
              Approve
            </Button>
            <Button
              variant="outlined"
              onClick={() => setAcceptModalOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </Card>
      </Modal>

      <Modal
        open={assignCampaignModalOpen}
        className="overflow-scroll flex flex-col place-content-center place-items-center"
        onClose={() => setAssignCampaignModalOpen(false)}
        onBackdropClick={() => setAssignCampaignModalOpen(false)}
      >
        <AssignCampaignModal user={user} />
      </Modal>
    </TableRow>
  );
};

export default CreatorTableRow;
