import { Button, Card, CardContent, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import Campaign from "@socialplug/common/interfaces/campaign";
import { useApi } from "@socialplug/common/utilities/api";
import Divider from "@uiw/react-divider";
import { compareAsc } from "date-fns";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

const CampaignDetailsModal: React.FC<any> = (props) => {
  const selectedCampaign: Campaign = props.campaign;
  const campaignBrand = props.brand;
  const [moodImageId, setMoodImageId] = useState(0);

  const [coverImageUrl, setCoverImageUrl] = useState<string | undefined>(
    undefined
  );
  const [, getPreSignedUrl] = useApi(
    "POST",
    "content",
    "render-pre-signed-url"
  );
  useEffect(() => {
    (async () => {
      const coverImageUrl = await getPreSignedUrl({
        body: { key: selectedCampaign.coverImage.id },
      });
      setCoverImageUrl(coverImageUrl.signedUrl);
    })();
  }, [getPreSignedUrl, selectedCampaign]);

  let history = useHistory();

  const campaignExpired = selectedCampaign
    ? compareAsc(new Date(selectedCampaign.endDate), new Date()) === -1
    : true;

  const onMoodNextClick = () => {
    if (selectedCampaign !== undefined && selectedCampaign !== null) {
      if (moodImageId < selectedCampaign.moodboard.length - 1) {
        setMoodImageId(moodImageId + 1);
      }
    }
  };

  const onMoodBackClick = () => {
    if (moodImageId > 0) {
      setMoodImageId(moodImageId - 1);
    }
  };

  const redirectToEditCampaign = () => {
    history.push(`/campaign/${selectedCampaign.id}/edit`);
  };

  const getHashTags = () => {
    var toReturn = "";
    selectedCampaign?.hashtags.forEach((hashtag) => {
      toReturn += hashtag + " ";
    });
    return toReturn;
  };

  return (
    <div className="flex flex-col place-content-center place-items-center w-screen lg:w-full">
      <Card className="m-4 lg:m-10 lg:max-w-3xl h-full lg:w-1/3">
        <CardContent>
          <div className="flex flex-col place-items-center place-content-center mt-2">
            {campaignExpired && (
              <div
                className="mt-2 rounded-lg bg-no-repeat place-content-center place-items-center inline-block"
                style={{
                  backgroundImage: `url(${selectedCampaign.coverImage?.url})`,
                }}
              >
                <div className=" bg-gray-600 bg-opacity-60 w-full inline-block">
                  <Typography className="ml-2 absolute font-bold text-xl text-white z-50">
                    Expired
                  </Typography>
                  <img
                    src={selectedCampaign.coverImage?.url}
                    style={{
                      visibility: "hidden",
                    }}
                    className="z-0 float-left left-0 w-full"
                    alt="cover expired"
                  />
                </div>
              </div>
            )}
            {!campaignExpired && (
              <img
                src={selectedCampaign.coverImage?.url}
                alt="cover active"
                className=" w-full mt-2 rounded-lg"
              />
            )}

            <Typography className="text-2xl mt-2 font-bold">
              {campaignBrand?.name}
            </Typography>
            <Typography>{selectedCampaign.title}</Typography>
            {selectedCampaign.type === "Physical" && (
              <Typography className="text-gray-500">
                {new Date(selectedCampaign.startDate).toLocaleDateString()}
              </Typography>
            )}

            <React.Fragment>
              <Divider />
              <Typography className="font-bold place-content-center">
                Campaign Description
              </Typography>
              <Typography>{selectedCampaign?.description}</Typography>
              <Divider />
              <Typography className="font-bold place-content-center">
                Call to Action
              </Typography>
              <Typography>{selectedCampaign?.callToAction}</Typography>
              <Divider />
              {selectedCampaign.type === "Online" && (
                <React.Fragment>
                  <Typography className="font-bold">URL</Typography>
                  <Typography className="text-center">
                    {selectedCampaign.url}
                  </Typography>
                  <Divider />
                </React.Fragment>
              )}

              <React.Fragment>
                <Typography className="font-bold">At Handles</Typography>
                <Typography>{selectedCampaign.atHandle}</Typography>
                <Divider />
              </React.Fragment>

              {selectedCampaign.hashtags !== null &&
                selectedCampaign.hashtags !== undefined && (
                  <React.Fragment>
                    <Typography className="font-bold">Hashtags</Typography>
                    <Typography>{getHashTags()}</Typography>
                    <Divider />
                  </React.Fragment>
                )}

              {selectedCampaign.type === "Physical" && (
                <React.Fragment>
                  <Typography className="font-bold">ADDRESS</Typography>
                  <Typography>{selectedCampaign.addressFormatted}</Typography>
                  <Divider />
                </React.Fragment>
              )}
              <Typography className="font-bold">Category</Typography>
              <Typography>{selectedCampaign?.category}</Typography>
              <Divider />
              <Typography className="font-bold">REWARD</Typography>
              <Typography>
                {selectedCampaign.rewardType} -{" "}
                {selectedCampaign.rewardDescription}
              </Typography>
              <Divider />
              {selectedCampaign.discountCode !== "" &&
                selectedCampaign.discountCode !== "****" && (
                  <React.Fragment>
                    <Typography className="font-bold">
                      Exclusive Website code
                    </Typography>
                    <Typography>{selectedCampaign.discountCode}</Typography>
                    <Divider />
                  </React.Fragment>
                )}

              <Typography className="font-bold">VISUAL DIRECTION</Typography>
              <div className="flex flex-row place-content-center place-items-center">
                <ArrowBackIosIcon
                  onClick={() => onMoodBackClick()}
                  fontSize="large"
                  className={moodImageId === 0 ? "fill-current text-white" : ""}
                />
                <img
                  src={selectedCampaign.moodboard[moodImageId].url}
                  className="w-3/5 lg:max-h-80"
                  alt="mood"
                />
                <ArrowForwardIosIcon
                  onClick={() => onMoodNextClick()}
                  fontSize="large"
                  className={
                    moodImageId === selectedCampaign.moodboard.length - 1
                      ? "fill-current text-white"
                      : ""
                  }
                />
              </div>
              <Divider />
              {!campaignExpired && (
                <React.Fragment>
                  <Typography className="font-bold">AVAILABLE UNTIL</Typography>
                  <Typography className="mb-1">
                    {new Date(selectedCampaign.startDate).toLocaleDateString()}
                    or no redemptions or budget is spent.
                  </Typography>
                </React.Fragment>
              )}

              <Divider />
              <Typography className="font-bold">CAMPAIGN OBJECTIVES</Typography>
              <ul className="mb-2 ml-4">
                {selectedCampaign.objectives.map((obj) => {
                  return (
                    <li key={obj} className="list-disc">
                      {obj}
                    </li>
                  );
                })}
              </ul>
              <Divider />

              <Typography className="font-bold">CONTENT GUIDELINES</Typography>
              {selectedCampaign.rules.map((rule) => (
                <li key={rule} className="list-disc">
                  {rule}
                </li>
              ))}

              {selectedCampaign.captionRules !== null && (
                <React.Fragment>
                  <Typography className="font-bold mt-2">
                    CAPTION GUIDELINES
                  </Typography>
                  {selectedCampaign.captionRules.map((rule) => (
                    <li key={rule} className="list-disc">
                      {rule}
                    </li>
                  ))}
                </React.Fragment>
              )}

              <Divider />

              <Button
                variant="outlined"
                className="mb-5 w-full hover:bg-blue-200 rounded-lg"
                startIcon={<CreateIcon />}
                onClick={() => redirectToEditCampaign()}
              >
                Edit Campaign
              </Button>
              <Button
                variant="outlined"
                className=" mb-5 w-full hover:bg-blue-200 rounded-lg"
                onClick={() => props.onClose()}
                startIcon={<CloseIcon />}
              >
                Close
              </Button>
            </React.Fragment>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default CampaignDetailsModal;
