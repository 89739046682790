import {
  Badge,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Typography,
} from "@material-ui/core";
import InstagramIcon from "@material-ui/icons/Instagram";
import Campaign from "@socialplug/common/interfaces/campaign";
import Commit from "@socialplug/common/interfaces/commit";
import Offer from "@socialplug/common/interfaces/offer";
import { useApi } from "@socialplug/common/utilities/api";
import { compareAsc } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BrandContext from "../context/BrandContext";

interface CampaignCardProps {
  campaign: Campaign;
}

const CampaignCard: React.FC<CampaignCardProps> = ({ campaign }) => {
  let history = useHistory();

  const { activeBrand } = useContext(BrandContext);
  const [commits, setCommits] = useState<Commit[]>();
  const [pendingCommits, setPendingCommits] = useState(0);
  const [offers, setOffers] = useState<Offer[]>();

  const cardOnClick = () => {
    history.push(`/campaign/${campaign.id}/details`);
  };

  const [, getCommits] = useApi("GET", "commit", "list");
  const [, getOffers] = useApi("GET", "offer", "list");

  useEffect(() => {
    (async () => {
      try {
        const foundCommits: Commit[] = await getCommits({
          queryParams: { campaignId: campaign.id },
        });
        const foundOffers: Offer[] = await getOffers({
          queryParams: { campaignId: campaign.id, accepted: true },
        });
        setOffers(foundOffers);
        setCommits(foundCommits);
        var commitsPending = 0;
        foundCommits.forEach((commit) => {
          if (commit.state !== "Completed" && commit.state !== "Declined")
            commitsPending++;
        });
        setPendingCommits(commitsPending);
      } catch {}
    })();
  }, [getCommits, getOffers, campaign]);

  const campaignExpired =
    compareAsc(new Date(campaign.endDate), new Date()) === -1;

  const getMoneySpent = () => {
    var toReturn = 0;
    offers?.forEach((offer) => {
      toReturn += offer.amount;
    });
    return toReturn;
  };

  const getRewardsRedeemed = () => {
    var toReturn = 0;
    commits?.forEach((commit) => {
      if (commit.state === "Completed") {
        toReturn++;
      }
    });
    return toReturn;
  };

  return (
    <div className="ml-2 mr-2 mb-4">
      <Badge badgeContent={pendingCommits} color="secondary" className="w-full">
        <Card className="w-full">
          <CardActionArea onClick={cardOnClick}>
            <CardMedia>
              <div className="bg-gray-500 bg-opacity-50 w-full h-full">
                <div
                  className="h-40 lg:h-52 "
                  style={{
                    backgroundImage: `url(${campaign.coverImage?.url})`,
                    backgroundSize: "100% 100%",
                  }}
                >
                  {campaignExpired && (
                    <div className="bg-gray-600 bg-opacity-60 w-full h-full">
                      <Typography className="ml-2 font-bold text-white">
                        Expired
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </CardMedia>
            <CardContent>
              <div className="grid grid-cols-12">
                <div className="col-span-11">
                  <Typography>
                    <strong>{activeBrand?.name}</strong>
                  </Typography>
                </div>
                <InstagramIcon />
              </div>
              <Typography>{campaign.title}</Typography>
              <Typography>
                {campaign.type === "Online" ? "Online" : "In Person"} -{" "}
                {campaign.rewardType}
              </Typography>
              <Divider />
              <div className="flex flex-col mt-2">
                <Typography>
                  Redemptions Remaining:{" "}
                  {campaign.maximumRedemptions - getRewardsRedeemed()}
                </Typography>
                <React.Fragment>
                  {campaign.budget && campaign.budget != null && (
                    <React.Fragment>
                      {campaign?.budget > 0 && (
                        <Typography>
                          £{getMoneySpent()}/£{campaign?.budget} spent
                        </Typography>
                      )}
                    </React.Fragment>
                  )}
                  {campaign.budget === null && <br />}
                </React.Fragment>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </Badge>
    </div>
  );
};
export default CampaignCard;
