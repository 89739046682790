import { Button, Typography } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ErrorContext from "../../../shared/context/ErrorContext";

const ErrorMessage: React.FC = () => {
  let history = useHistory();
  const { setError } = useContext(ErrorContext);
  useEffect(() => {
    setError(undefined);
  }, []);
  return (
    <div className="mt-10 ml-4">
      <Typography className="text-red-500 text-4xl">
        Uh-oh something went wrong
      </Typography>
      <Typography className="text-red-500 mt-4 text-2xl">
        Oh no! Please drop us a quick email explaining how you found this issue
        so we can resolve it as soon as possible:
      </Typography>
      <Typography className="text-red-500 text-xl">
        support@socialplug.co.uk
      </Typography>

      <Button
        className="mt-2 rounded-lg hover:bg-blue-200"
        startIcon={<HomeIcon />}
        onClick={() => history.push("/")}
      >
        Go to Homepage
      </Button>
    </div>
  );
};

export default ErrorMessage;
