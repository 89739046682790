import { TableCell, TableRow } from "@material-ui/core";
import Campaign from "@socialplug/common/interfaces/campaign";
import Offer from "@socialplug/common/interfaces/offer";
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

const PendingOffersTableRow: React.FC<any> = (props) => {
  const offer: Offer = props.offer;

  const [campaign, setCampaign] = useState<Campaign>();
  const [creator, setCreator] = useState<User>();

  const [, getCampaign] = useApi("GET", "campaign", "fetch");
  const [, getCreator] = useApi("GET", "creator", "fetch");

  let history = useHistory();

  useEffect(() => {
    try {
      (async () => {
        const foundUser: User = await getCreator({
          queryParams: { userId: offer.offeredById },
        });
        setCreator(foundUser);
        const foundCampaign: Campaign = await getCampaign({
          queryParams: { campaignId: offer.campaignId },
        });
        setCampaign(foundCampaign);
      })();
    } catch {}
  }, [offer, getCreator, getCampaign]);

  const rowOnClick = () => {
    if (campaign != null && offer != null) {
      history.push(`/campaign/${campaign.id}/submission/${offer.commitId}`);
    }
  };

  return (
    <React.Fragment>
      {campaign && creator && (
        <TableRow onClick={() => rowOnClick()}>
          <TableCell align="center">{campaign?.title}</TableCell>
          <TableCell align="center">{creator.name}</TableCell>
          <TableCell align="center">{offer.amount}</TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

export default PendingOffersTableRow;
