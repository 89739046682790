import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";

const MultipleTagTextField: React.FC<any> = ({
  defaultValue,
  inputProps,
  helperText,
  errorText,
  error,
  onChange,
  placeholder,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState<string[]>(defaultValue);

  return (
    <Autocomplete
      multiple
      options={[]}
      limitTags={2}
      defaultValue={defaultValue}
      value={internalValue}
      className="w-96 mt-8 max-w-full"
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          const allVals = [...internalValue, newValue];
          setInternalValue(allVals);
          onChange(allVals);
        } else if (typeof newValue === "object") {
          const allVals = [...newValue];
          setInternalValue(allVals);
          onChange(allVals);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          helperText={error ? errorText : helperText}
          error={error}
          placeholder={placeholder}
        />
      )}
    />
  );
};
export default MultipleTagTextField;
