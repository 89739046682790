import { Button, Typography } from "@material-ui/core";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useContext } from "react";
import BrandContext from "../../context/BrandContext";

const Stripe = (window as any)["Stripe"];
const stripe = Stripe(process.env.REACT_APP_STRIPE_KEY);

const CheckoutStarted: React.FC = () => {
  const [, createStripeSession] = useApi("POST", "financial", "create-session");
  const { activeBrand } = useContext(BrandContext);

  const onContinueCheckoutClick = async () => {
    try {
      if (activeBrand && activeBrand != null) {
        const stripeSession = await createStripeSession({
          body: {
            priceId: process.env.REACT_APP_STRIPE_PRICE_ID,
            brandId: activeBrand.id,
          },
        });
        stripe.redirectToCheckout({
          sessionId: stripeSession.sessionId,
        });
      }
    } catch {}
  };

  return (
    <div className="flex flex-col place-content-center place-items-center">
      <Typography className="text-4xl mt-10 font-bold text-center">
        You must complete the sign up process to proceed.
      </Typography>
      <img
        src="https://i.imgur.com/Kgpchr1.png"
        alt="continue checkout"
        className="mt-10"
      />
      <Button
        variant="outlined"
        onClick={() => onContinueCheckoutClick()}
        className="mt-10 lg:hover:bg-green-300 lg:hover:bg-opacity-50"
      >
        Continue checkout
      </Button>
    </div>
  );
};

export default CheckoutStarted;
