import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Brand from "@socialplug/common/interfaces/brand";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";
import BrandRow from "./BrandRow";

const BrandTable: React.FC<any> = () => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [, listBrands] = useApi("GET", "admin", "listBrands");
  useEffect(() => {
    (async () => {
      const foundBrands: Brand[] = await listBrands();
      setBrands(foundBrands);
    })();
  }, [listBrands]);

  return (
    <React.Fragment>
      {brands.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" className="font-bold">
                  Brand
                </TableCell>
                <TableCell align="center" className="font-bold">
                  Instagram Hanldle
                </TableCell>
                <TableCell align="center" className="font-bold">
                  Email
                </TableCell>
                <TableCell align="center" className="font-bold">
                  Phone Number
                </TableCell>
                <TableCell align="center" className="font-bold">
                  Active Sub
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {brands.map((brand: Brand) => (
                <React.Fragment>
                  {brand.subscriptionStatus != null && (
                    <BrandRow brand={brand} key={brand.id} />
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </React.Fragment>
  );
};

export default BrandTable;
