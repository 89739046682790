import { useParams } from "react-router";
import InfluencerInformation from "./InfluencerInformation";

type InfluencerProfilePageParams = {
  uid: string;
};

const InfluencerProfile = () => {
  const { uid } = useParams<InfluencerProfilePageParams>();

  return <InfluencerInformation userId={uid} />;
};

export default InfluencerProfile;
