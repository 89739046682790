import { Button, Checkbox, TextField, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";
import Commit from "@socialplug/common/interfaces/commit";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import CampaignContext from "../../context/CampaignContext";
import YesNoDialog from "../YesNoDialog";

const Offer: React.FC<any> = (props) => {
  const selectedCommit: Commit = props.commit;
  const { campaign } = useContext(CampaignContext);

  const [declineClicked, setDeclineClicked] = useState(false);
  const [actionTaken, setActionTaken] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [requestRevision, setRequestRevision] = useState(false);

  const [, editCommit] = useApi("POST", "commit", "edit");

  let history = useHistory();
  if (campaign === undefined) {
    history.push("/");
  }

  const acceptOfferOnClick = () => {
    setOpenDialog(true);
  };

  const dialogOnYesClick = () => {
    (async () => {
      if (campaign?.rewardType === "Money") {
        try {
          await editCommit({
            body: {
              commitId: selectedCommit?.id,
              changes: { state: "SubmissionApproved" },
            },
          });
        } catch {}
      } else {
        try {
          await editCommit({
            body: {
              commitId: selectedCommit?.id,
              changes: { state: "Completed" },
            },
          });
        } catch {}
      }
    })();
    setActionTaken(true);
    setOpenDialog(false);
  };

  const dialogOnNoClick = () => {
    setOpenDialog(false);
  };

  const cofirmDecline = () => {
    if (requestRevision) {
      (async () => {
        try {
          await editCommit({
            body: {
              commitId: selectedCommit?.id,
              changes: {
                state: "ContentPending",
                reasonForDecline: declineReason,
                revisionRequested: requestRevision,
              },
            },
          });
        } catch {}
      })();
    } else {
      (async () => {
        try {
          await editCommit({
            body: {
              commitId: selectedCommit?.id,
              changes: {
                state: "Declined",
                reasonForDecline: declineReason,
              },
            },
          });
        } catch {}
      })();
    }
    setOpenDialog(false);
    setActionTaken(true);
  };
  return (
    <React.Fragment>
      {campaign && selectedCommit && (
        <div className="flex flex-col place-items-center place-content-center">
          <div className="flex gap-5 mt-5">
            <Button
              className="rounded-lg text-blue-500 border-blue-500"
              startIcon={<CheckIcon />}
              onClick={() => acceptOfferOnClick()}
              variant="outlined"
              disabled={actionTaken}
            >
              Accept
            </Button>

            <YesNoDialog
              open={openDialog}
              title="Approve content"
              text={`Approve the content from this user?`}
              yesOnClick={() => dialogOnYesClick()}
              noOnClick={() => dialogOnNoClick()}
            />

            <Button
              className="rounded-lg border-red-500 text-red-500"
              startIcon={<CloseIcon />}
              onClick={() => setDeclineClicked(!declineClicked)}
              variant="outlined"
              disabled={actionTaken}
            >
              Revise
            </Button>
          </div>

          {declineClicked && (
            <React.Fragment>
              <TextField
                onChange={(e) => setDeclineReason(e.target.value)}
                variant="outlined"
                className="w-80 mb-2 lg:5/6 mt-3"
                placeholder="Reason for decline"
                required
              />

              <div className="flex flex-row place-items-center place-content-center">
                <Checkbox
                  name="RequestRevision"
                  color="primary"
                  className="float-left"
                  onChange={() => setRequestRevision(!requestRevision)}
                />
                <Typography>Request Revision</Typography>
              </div>

              <Button
                startIcon={<SendIcon />}
                className={
                  declineReason === ""
                    ? "w-80 bg-gray-300 text-black"
                    : "w-80 bg-red-500 text-white"
                }
                onClick={() => cofirmDecline()}
                variant="outlined"
              >
                Confirm revision
              </Button>
            </React.Fragment>
          )}
        </div>
      )}
      {(campaign === null || selectedCommit === null) && (
        <div>
          <Typography className="text-red-500">No offer found.</Typography>
        </div>
      )}
    </React.Fragment>
  );
};

export default Offer;
