import {
  Avatar,
  Button,
  Card,
  Modal,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import Commit from "@socialplug/common/interfaces/commit";
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

const CompletedAssignedCampaignsRow: React.FC<any> = (props) => {
  const user: User = props.user;
  const [commit, setCommit] = useState<Commit>();
  const [actionMade, setActionMade] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [waitingModalOpen, setWaitingModalOpen] = useState(false);

  const [, assignCreatorStatus] = useApi(
    "POST",
    "admin",
    "assignCreatorStatus"
  );
  const [, listCommits] = useApi("GET", "commit", "list");

  useEffect(() => {
    try {
      (async () => {
        if (user && user != null) {
          const foundCommits: Commit[] = await listCommits({
            queryParams: {
              campaignId: user.assignedCampaignId,
              userId: user.id,
            },
          });
          if (foundCommits && foundCommits != null) setCommit(foundCommits[0]);
        }
      })();
    } catch {}
  }, [user, listCommits]);

  let history = useHistory();

  const changeStatus = async (status: string) => {
    await assignCreatorStatus({
      body: {
        creatorId: user.id,
        status: status,
      },
    });
    setActionMade(true);
    setApproveModalOpen(false);
    setRejectModalOpen(false);
    setWaitingModalOpen(false);
  };

  const redirectToCommit = () => {
    if (commit && commit != null)
      history.push(`/campaign/${commit?.campaignId}/submission/${commit?.id}`);
  };

  return (
    <TableRow>
      <TableCell align="center">
        <div className="flex flex-col place-content-center place-items-center">
          <Avatar src={user?.profileImage?.url} />
          <Typography>{user.name}</Typography>
        </div>
      </TableCell>
      <TableCell align="center">
        <Button variant="outlined" onClick={() => redirectToCommit()}>
          View Commit
        </Button>
      </TableCell>
      <TableCell align="center">
        <Button
          variant="outlined"
          disabled={actionMade}
          onClick={() => setApproveModalOpen(true)}
        >
          Approve
        </Button>
      </TableCell>
      <TableCell align="center">
        <Button
          disabled={actionMade}
          onClick={() => setWaitingModalOpen(true)}
          variant="outlined"
        >
          Send back to waiting
        </Button>
      </TableCell>
      <TableCell align="center">
        <Button
          disabled={actionMade}
          variant="outlined"
          className="text-red-500"
          onClick={() => setRejectModalOpen(true)}
        >
          Reject
        </Button>
      </TableCell>

      <Modal
        open={approveModalOpen}
        className="overflow-scroll flex flex-col place-content-center place-items-center "
        onClose={() => setApproveModalOpen(false)}
        onBackdropClick={() => setApproveModalOpen(false)}
      >
        <Card className="flex flex-col">
          <div className="m-4">
            <Typography>Approve {user.name}?</Typography>
            <Button
              variant="outlined"
              className="text-green-500 mr-4 "
              onClick={() => changeStatus("Approved")}
            >
              Approve
            </Button>
            <Button
              variant="outlined"
              onClick={() => setApproveModalOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </Card>
      </Modal>

      <Modal
        open={rejectModalOpen}
        className="overflow-scroll flex flex-col place-content-center place-items-center "
        onClose={() => setRejectModalOpen(false)}
        onBackdropClick={() => setRejectModalOpen(false)}
      >
        <Card className="flex flex-col">
          <div className="m-4">
            <Typography>Reject {user.name}?</Typography>
            <Button
              variant="outlined"
              className="text-red-500 mr-4 "
              onClick={() => changeStatus("Rejected")}
            >
              Reject
            </Button>
            <Button
              variant="outlined"
              onClick={() => setRejectModalOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </Card>
      </Modal>

      <Modal
        open={waitingModalOpen}
        className="overflow-scroll flex flex-col place-content-center place-items-center "
        onClose={() => setWaitingModalOpen(false)}
        onBackdropClick={() => setWaitingModalOpen(false)}
      >
        <Card className="flex flex-col">
          <div className="m-4">
            <Typography>
              Send {user.name} back to waiting?
            </Typography>
            <Button
              variant="outlined"
              className="text-red-500 mr-4 "
              onClick={() => changeStatus("Waiting")}
            >
              Send back to waiting
            </Button>
            <Button
              variant="outlined"
              onClick={() => setWaitingModalOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </Card>
      </Modal>
    </TableRow>
  );
};

export default CompletedAssignedCampaignsRow;
