import { Button, Typography } from "@material-ui/core";
import { useApi } from "@socialplug/common/utilities/api";
import React from "react";

const InactiveSub: React.FC = () => {
  const [, getSubscriptionManagementLink, subLinkErr] = useApi(
    "POST",
    "financial",
    "create-portal-session"
  );

  const onManageSubClick = async () => {
    try {
      const res = await getSubscriptionManagementLink();
      if (subLinkErr.current) {
        console.log("Could not get link for stripe");
        subLinkErr.current = undefined;
        return;
      }
      if (res.url != null) {
        window.open(res.url);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="flex flex-col place-content-center place-items-center">
      <Typography className="text-4xl mt-10 font-bold text-center">
        Your subscription is currently inactive.
      </Typography>
      <Typography className="text-4xl font-bold text-center">
        Please re-activate your subscription to proceed.
      </Typography>
      <img
        src="https://i.imgur.com/4s1Ci34.png"
        className="mt-10"
        alt="inactive sub"
      />
      <Button
        variant="outlined"
        onClick={() => onManageSubClick()}
        className="mt-10 lg:hover:bg-green-300 lg:hover:bg-opacity-50"
      >
        Manage subscription
      </Button>
    </div>
  );
};

export default InactiveSub;
