import {
  Avatar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import ListIcon from "@material-ui/icons/List";
import MenuIcon from "@material-ui/icons/Menu";
import Campaign from "@socialplug/common/interfaces/campaign";
import Commit from "@socialplug/common/interfaces/commit";
import Divider from "@uiw/react-divider";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import CampaignContext from "../context/CampaignContext";

const countCommits = (commits: Commit[] | undefined, state: String) => {
  var toReturn = 0;
  commits?.forEach((commit) => {
    if (commit.state === state) toReturn++;
  });
  return toReturn;
};

const countInNegotiation = (commits: Commit[] | undefined) => {
  var toReturn = 0;
  commits?.forEach((commit) => {
    if (
      commit.state === "InNegotiation" ||
      commit.state === "SubmissionApproved"
    )
      toReturn++;
  });
  return toReturn;
};

const CampaignDrawer: React.FC<any> = (props) => {
  const campaign: Campaign = props.campaign;
  const { allCommits } = useContext(CampaignContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("");

  let history = useHistory();

  const path = window.location.pathname;
  const part = path.split("/").pop();

  useEffect(() => {
    switch (part) {
      case "details":
        setActiveTab("Overview");
        break;
      case "requests":
        setActiveTab("Requests");
        break;
      case "ContentPending":
        setActiveTab("Awaiting Submission");
        break;
      case "AwaitingSubmissionApproval":
        setActiveTab("Submissions");
        break;
      case "inNegotiation":
        setActiveTab("In Negotiation");
        break;
      case "Completed":
        setActiveTab("Completed");
        break;
      case "Declined":
        setActiveTab("Declined");
        break;
      case "edit":
        setActiveTab("Edit Campaign");
        break;
    }
  }, [part]);

  const isActive = (item: string) => {
    return activeTab === item;
  };

  const classes = (item: string) => {
    return isActive(item)
      ? "bg-yellow-300 bg-opacity-50"
      : "lg:hover:bg-yellow-300 lg:hover:bg-opacity-50";
  };

  const redirectToDashboard = () => {
    history.push("/dashboard/home");
  };

  const redirect = (path: string) => {
    history.push(`/campaign/${campaign.id}/${path}`);
    setDrawerOpen(false);
  };

  const redirectToSubmissions = (state: string) => {
    history.push(`/campaign/${campaign.id}/submissions/${state}`);
    setDrawerOpen(false);
  };

  const drawerContent = (
    <div>
      <div className="flex flex-row place-items-center mt-5 mb-5">
        <Avatar className="mr-2 ml-5" src={campaign?.coverImage?.url} />
        <Typography className="w-full">{campaign?.title}</Typography>
      </div>

      <Divider />
      <List className="w-4/5">
        <ListItem
          className={`${classes("Overview")} rounded-r-full`}
          onClick={() => redirect("details")}
        >
          <ListIcon />
          <ListItemText>Overview</ListItemText>
        </ListItem>
        <ListItem
          className={`${classes("Requests")} rounded-r-full`}
          onClick={() => redirect("requests")}
        >
          <ListIcon />
          <ListItemText>
            Requests ({countCommits(allCommits, "AwaitingApproval")})
          </ListItemText>
        </ListItem>
        <ListItem
          className={`${classes("Awaiting Submission")} rounded-r-full`}
          onClick={() => redirectToSubmissions("ContentPending")}
        >
          <ListIcon />
          <ListItemText>
            Awaiting Submission ({countCommits(allCommits, "ContentPending")})
          </ListItemText>
        </ListItem>
        <ListItem
          className={`${classes("Submissions")} rounded-r-full`}
          onClick={() => redirectToSubmissions("AwaitingSubmissionApproval")}
        >
          <ListIcon />
          <ListItemText>
            Submissions (
            {countCommits(allCommits, "AwaitingSubmissionApproval")})
          </ListItemText>
        </ListItem>
        <ListItem
          className={`${classes("In Negotiation")} rounded-r-full`}
          onClick={() => redirectToSubmissions("inNegotiation")}
        >
          <ListIcon />
          <ListItemText>
            In Negotiation ({countInNegotiation(allCommits)})
          </ListItemText>
        </ListItem>
        <ListItem
          className={`${classes("Completed")} rounded-r-full`}
          onClick={() => redirectToSubmissions("Completed")}
        >
          <CheckIcon />
          <ListItemText>
            Completed ({countCommits(allCommits, "Completed")})
          </ListItemText>
        </ListItem>
        <ListItem
          className={`${classes("Declined")} rounded-r-full`}
          onClick={() => redirectToSubmissions("Declined")}
        >
          <ClearIcon />
          <ListItemText>
            Declined ({countCommits(allCommits, "Declined")})
          </ListItemText>
        </ListItem>
        <ListItem
          className={`${classes("Edit Campaign")} rounded-r-full`}
          onClick={() => redirect("edit")}
        >
          <CreateIcon />
          <ListItemText>Edit Campaign</ListItemText>
        </ListItem>

        <ListItem
          className={`${classes("home")} rounded-r-full`}
          onClick={() => redirectToDashboard()}
        >
          <ArrowBackIcon />
          <ListItemText>Dashboard</ListItemText>
        </ListItem>
      </List>
    </div>
  );

  return (
    <React.Fragment>
      <Toolbar className="lg:hidden lg:bg-transparent bg-blue-400 text-white">
        <IconButton onClick={() => setDrawerOpen(true)}>
          <MenuIcon className="fill-current text-white" />
        </IconButton>
        <Typography className="font-bold text-xl">{campaign?.title}</Typography>
      </Toolbar>

      <Drawer
        anchor="left"
        open={drawerOpen}
        variant="temporary"
        ModalProps={{
          keepMounted: true,
        }}
        classes={{ paper: "w-4/5" }}
        onBackdropClick={() => setDrawerOpen(false)}
      >
        {drawerContent}
      </Drawer>

      <Drawer
        className="hidden lg:block"
        variant="permanent"
        open
        classes={{ paper: "w-72" }}
      >
        {drawerContent}
      </Drawer>
    </React.Fragment>
  );
};

export default CampaignDrawer;
