import { Avatar, TableCell, TableRow } from "@material-ui/core";
import Commit from "@socialplug/common/interfaces/commit";
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";

const AwaitingSubmissionRow: React.FC<any> = (props) => {
  const commit: Commit = props.commit;
  const [user, setUser] = useState<User>();
  const [, fetchUser] = useApi("GET", "creator", "fetch");

  useEffect(() => {
    (async () => {
      try {
        const foundUser: User = await fetchUser({
          queryParams: { userId: commit.userId },
        });
        setUser(foundUser);
      } catch {}
    })();
  }, [commit, fetchUser]);

  const instagramHandle = user?.instagramAccountHandle !== '' ?
    `@${user?.instagramAccountHandle}` : ''

  const tiktokHandle = user?.tiktokAccountHandle !== '' ?
    `@${user?.tiktokAccountHandle}` : ''

  return (
    <React.Fragment>
      {user != null && (
        <TableRow>
          <TableCell align="center">
            <div className="flex flex-col place-content-center place-items-center">
              <Avatar src={user?.profileImage?.url} />
            </div>
          </TableCell>
          <TableCell align="center">{user.name}</TableCell>
          <TableCell align="center">{instagramHandle}</TableCell>
          <TableCell align="center">{tiktokHandle}</TableCell>
          <TableCell align="center">{user.email}</TableCell>
          <TableCell align="center">{user?.followers}</TableCell>
          <TableCell align="center">
            {new Date(commit?.lastModified).toLocaleDateString()}
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

export default AwaitingSubmissionRow;
