import Auth from "@aws-amplify/auth";
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Notification from "@socialplug/common/interfaces/notification";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";

const UserNotifications = () => {
  const [notifications, setNotifications] = useState<Notification[]>();
  const [, listNotifications] = useApi("GET", "notification", "list");
  const [, markAsSeen] = useApi("POST", "notification", "markAsSeen");

  useEffect(() => {
    (async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        var foundNotifications: Notification[] = await listNotifications({
          queryParams: { userId: user.username, seen: false },
        });
        foundNotifications.reverse();
        setNotifications(foundNotifications);
      } catch {}
    })();
  }, [listNotifications]);

  const notificationDeleteOnClick = (notification: Notification) => {
    (async () => {
      await markAsSeen({
        body: { notificationId: notification.id },
      });
    })();
    if (notifications != null) {
      var array: Notification[] = notifications;
      array = array.filter(function (item) {
        return item !== notification;
      });
      setNotifications(array);
    }
  };

  return (
    <React.Fragment>
      <List component={Paper} className="w-full">
        <React.Fragment>
          {notifications?.map((notification) => (
            <ListItem divider>
              <NotificationsIcon />
              <ListItemText>
                <div className="flex flex-col ml-2">
                  <Typography className="text-lg font-bold">
                    {notification.title}
                  </Typography>
                  <Typography> {notification.description}</Typography>
                  <Typography>
                    Date: {new Date(notification.created).toLocaleDateString()}
                  </Typography>
                </div>
              </ListItemText>
              <div onClick={() => notificationDeleteOnClick(notification)}>
                <ClearIcon className="fill-current text-red-500" />
              </div>
            </ListItem>
          ))}
        </React.Fragment>
      </List>
    </React.Fragment>
  );
};

export default UserNotifications;
