import { Button, TableCell, TableRow, Typography } from "@material-ui/core";
import Brand from "@socialplug/common/interfaces/brand";
import Campaign from "@socialplug/common/interfaces/campaign";
import Commit from "@socialplug/common/interfaces/commit";
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

const ToChaseCommitsRow: React.FC<any> = (props) => {
  const commit: Commit = props.commit;
  const [campaign, setCampaign] = useState<Campaign>();
  const [user, setUser] = useState<User>();
  const [brand, setBrand] = useState<Brand>();

  const [, getCampaign] = useApi("GET", "campaign", "fetch");
  const [, getUser] = useApi("GET", "creator", "fetch");
  const [, getBrand] = useApi("GET", "brand", "fetch");

  let history = useHistory();

  useEffect(() => {
    try {
      (async () => {
        const foundCampaign = await getCampaign({
          queryParams: { campaignId: commit.campaignId },
        });
        setCampaign(foundCampaign);
        const foundUser = await getUser({
          queryParams: { userId: commit.userId },
        });
        setUser(foundUser);
        const foundBrand = await getBrand({
          queryParams: { brandId: foundCampaign.brandId },
        });
        setBrand(foundBrand);
      })();
    } catch {}
  }, [commit, getCampaign, getBrand, getUser]);

  const onViewCommitClick = () => {
    if (campaign != null) {
      history.push(`/campaign/${campaign.id}/submission/${commit.id}`);
    }
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell align="center">
          <Typography>{user?.name}</Typography>
          <Typography>{user?.email}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{campaign?.title}</Typography>
          <Typography>{brand?.emailAddress}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{commit.state}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>
            {new Date(commit.lastModified).toLocaleDateString()}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Button variant="outlined" onClick={() => onViewCommitClick()}>
            View Commit
          </Button>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default ToChaseCommitsRow;
