import { Button, TableCell, TableRow, Typography } from "@material-ui/core";
import Campaign from "@socialplug/common/interfaces/campaign";
import { useHistory } from "react-router";

const CampaignRow: React.FC<any> = (props) => {
  const campaign: Campaign = props.campaign;

  let history = useHistory();

  const onCampaignRedirectClick = () => {
    history.push(`/campaign/${campaign.id}/details`);
  };

  return (
    <TableRow>
      <TableCell align="center">
        <Typography>{campaign.title}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>{campaign.brandName}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>{campaign.type}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>{campaign.rewardType}</Typography>
      </TableCell>
      <TableCell align="center">
        <Button variant="outlined" onClick={() => onCampaignRedirectClick()}>
          View Campaign
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default CampaignRow;
